import {
    Navigate,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import { useAuthenticationState } from '../context/Auth.context';
import Dashboard from '../layout/Dashboard';
import Login from '../views/login/Login';
import allRoutes from './routes';
import TermsAndCondtions from '../views/Terms';
import PrivacyPolicy from '../views/PrivacyPolicy';
import { Toaster } from 'react-hot-toast';

function GenerateRoutes() {
    const { user } = useAuthenticationState();
    let location = useLocation();
    const navigate = useNavigate();

    let routes = [];

    const delegateRoutePaths = [
        'events',
        'help-requests',
        'job-seekers',
        'alumini-map-list',
        // "posted-jobs",
        'announcements',
        'rsvp',
    ];

    const cchRoutePaths = [
        'cityChapterdashboard',
        'city-chapters',
        'city-chapter-map',
        'alumini-map-list',
        'alumni-details',
        'alumni-personaltag',
        'gc-council',
        // 'institute-member-details',
        'batch-head',
        'posted-jobs',
        'help-requests',
        'job-seekers',
        'events',
        'announcements',
        'mark-as-safe',
        'rsvp',
        'safety-response',
        'promos',
    ];

    const classRepStudentCouncilPaths = [
        'classrepdashboard',
        'student-details',
        'student-council',
        'student-personaltag',
        'student-job-seekers',
        'alumini-map-list',
        'mark-as-safe',
        'posted-jobs',
        'help-requests',
        'rsvp',
        'safety-response',
    ];
    // const newRolesRoutes = ['student-council', 'class-representative'];
    const institutememberRoutePaths = [
        'institute-member-dashboard',
        'alumni-details',
        'student-details',
        // 'institute-member-details',
        'faculty-details',
        'city-chapters',
        'events',
        'announcements',
        'blood-donor',
        'alumini-map-list',
        'rsvp',
        'mark-as-safe',
        'safety-response',
        'help-requests',
        'posted-jobs',

        // 'student-council',
        // 'class-representative',
    ];

    const adminUnWantedPath = [
        'institute-member-dashboard',
        'classrepdashboard',
        'student-job-seekers',
        'batchRep-dashboard',
        'student-council-dashboard',
        'sc-mark-as-safe',
        'blood-donor',
        'promos',
        'cityChapterdashboard',
        'cityChapterdashboard',
    ];
    const studentcouncilRoutePaths = [
        'student-council-dashboard',
        'student-details',
        'student-personaltag',
        'class-representative',
        'alumini-map-list',
        // 'institute-member-details',
        // 'city-chapters',
        'student-council',
        'sc-mark-as-safe',
        'announcements',
        'events',
        'safety-response',
        'help-requests',
        'student-job-seekers',
        'posted-jobs',
        'rsvp',
        'promos',
    ];
    const batchRepRoutePaths = [
        'batchRep-dashboard',
        'alumni-details',
        // 'institute-member-details',
        'city-chapters',
        'alumini-map-list',
        'gc-council',
        'alumni-personaltag',
        'events',
        'announcements',
        'job-seekers',
        'mark-as-safe',
        'promos',
        'job-seekers',
        'posted-jobs',
        'help-requests',
        'student-council',
        'city-chapter-map',
        'safety-response',
        // 'sc-mark-as-safe',
        'rsvp',
        'blood-donor',
    ];

    switch (user.Role?.toLowerCase()) {
        // switch ('cch') {
        case 'admin':
        case 'gcm':
            routes = allRoutes.filter(
                (route) => !adminUnWantedPath.includes(route.path)
            );
            break;
        case 'delegate':
            routes = allRoutes.filter((route) =>
                batchRepRoutePaths.includes(route.path)
            );
            break;

        case 'cch':
            routes = allRoutes.filter((route) =>
                cchRoutePaths.includes(route.path)
            );
            break;

        case 'delegate + cch':
            routes = allRoutes.filter((route) =>
                [...delegateRoutePaths, ...cchRoutePaths].includes(route.path)
            );
            break;
        case 'institutemember':
            routes = allRoutes.filter((route) =>
                institutememberRoutePaths.includes(route.path)
            );
            break;
        case 'classrepresentative':
            routes = allRoutes.filter((route) =>
                ['classrepdashboard', ...classRepStudentCouncilPaths].includes(
                    route.path
                )
            );
            break;

        case 'studentcouncil':
            routes = allRoutes.filter((route) =>
                studentcouncilRoutePaths.includes(route.path)
            );
            break;

        case 'classrep + student council':
            routes = allRoutes.filter((route) =>
                studentcouncilRoutePaths.includes(route.path)
            );
            break;

        default:
            break;
    }

    return (
        <>
            <Routes>
                <Route exact path='/login' element={<Login />} />

                <Route exact path='/terms' element={<TermsAndCondtions />} />
                <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route exact path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path={'/'} element={<Dashboard routes={routes} />}>
                    {routes.map((route) => (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={<route.page />}
                        />
                    ))}
                </Route>

                <Route
                    path='*'
                    element={
                        <Navigate
                            to={'/login'}
                            state={{ from: location }}
                            replace
                        />
                    }
                />
            </Routes>
            <Toaster />
        </>
    );
}

export default GenerateRoutes;
