import React, { useState } from 'react';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import StudentPersonalTagForm from './StudentPersonalTagForm';
import { useAuthenticationState } from '../../context/Auth.context';
import PaginatedTable from '../../components/table/PaginatedTable';
import GlobalSearch from '../../components/GlobalSearch';
import DownloadDataAsExcel from '../../components/DownloadDataAsExcel';

function StudentPersonalTag() {
    const { user } = useAuthenticationState();
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    let getApi = endpoints.alumniPersonalTag.get;
    let downloadUrl = `${endpoints.alumniPersonalTag.downloadExcel}?tagRelatedTo=student`
    if (["STUDENTCOUNCIL"].includes(user.Role) || user.Role === 'CLASSREP + STUDENT COUNCIL') {
        getApi = endpoints.alumniPersonalTag.getSc;
        downloadUrl = `${endpoints.alumniPersonalTag.downloadExcelSc}?tagRelatedTo=student`
            ;
    } else if (["CLASSREPRESENTATIVE"].includes(user.Role)) {
        getApi = endpoints.alumniPersonalTag.getCr;
        downloadUrl = `${endpoints.alumniPersonalTag.downloadExcelCr}?tagRelatedTo=student`
            ;

    }
    let url = `${getApi}student?page=${page}&limit=10`;


    if (searchTerm) {
        url = `${getApi}student?searchTerm=${searchTerm}&page=${page}&limit=10`;
        downloadUrl += `&searchTerm=${searchTerm}`
    }


    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };


    const fetchUtils = useAxiosGet(url);

    const fetchUtilsWithFilteredData = {
        ...fetchUtils
    };

    const userType = [
        'CLASSREPRESENTATIVE',
        'CLASSREP + STUDENTCOUNCIL',
    ];



    const cellModifier = {
        alumni_personal_tags: ({ value }) => {
            // Split the comma-separated string into an array
            const uniqueTags = value ? value?.split(',') : [];

            return (
                <div
                    style={{
                        maxWidth: '500px',
                        overflow: 'auto',
                        paddingBottom: '5px',
                    }}
                >
                    {uniqueTags?.map((tagName) => (
                        <div
                            key={tagName.trim()}
                            className="p-2 me-2 d-inline-block small"
                            style={{
                                background: 'rgba(95, 179, 246, 0.1)',
                                borderRadius: '10px',
                                border: '1px solid #e6eced',
                                boxShadow: "3px 3px 2px rgba(0, 0, 0, 0.4)",
                            }}
                        >
                            {tagName?.trim()} {/* Display the trimmed tag */}
                        </div>
                    ))}
                </div>
            );
        },
    };
    const handleNext = (page) => {
        setPage(page);
    };

    const handlePrevious = (page) => {
        setPage(page);
    };
    return (
        <>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                endpoints={endpoints.alumniPersonalTag}
                Form={StudentPersonalTagForm}
                showSearch={false}
                {...viewProps.StudentPersonalTag}
                cellModifier={cellModifier}
                addBtnLabel='Add Mapping'
                formSize='md'
                irremovable
                immutable={userType.includes(user.Role)}
                insertable={!userType.includes(user.Role)}
                {...fetchUtilsWithFilteredData}
                response={fetchUtils.response.responseData}
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm}
                        setSearchTerm={handleSearch}
                        placeholder="Search by student name"
                    />
                }
                downloadable={false}
                headerExtras={
                    <DownloadDataAsExcel
                        filename={"Student Personal Tag Mapping Details"}
                        apiEndpoint={downloadUrl}
                    />
                }
            />
        </>
    );
}

export default StudentPersonalTag;
