import React, { useState } from "react";
import CRUDTable from "../../components/table/CRUDTable";
import { useAxiosGet } from "../../hooks/axiosHooks";
import endpoints from "../../endpoints";
import StudentForm from "./StudentForm";
import viewProps from "../viewProps";
import BulkUpload from "../../components/BulkUpload";
import { useLocation } from "react-router-dom";
import { useAuthenticationState } from "../../context/Auth.context";
import errorObjects from "./studentErrorObjects";
import objCloneKeyValue from "../../helpers/objCloneKeyValue";
import PaginatedTable from "../../components/table/PaginatedTable";
import DownloadDataAsExcel from "../../components/DownloadDataAsExcel";
import GlobalSearch from "../../components/GlobalSearch";

function StudentDetails() {
    const { user } = useAuthenticationState();

    let getApi = endpoints.alumniDetail.getStudentOnly;
    let downloadUrl = endpoints.alumniDetail.studentdownloadExcel;

    if (["STUDENTCOUNCIL"].includes(user.Role) || user.Role === 'CLASSREP + STUDENT COUNCIL') {
        getApi = endpoints.alumniDetail.getStudentStudentCouncil;
        downloadUrl = endpoints.alumniDetail.studentdownloadExcelSc;
    } else if (["CLASSREPRESENTATIVE"].includes(user.Role)) {
        getApi = endpoints.alumniDetail.getStudentClassRep;
        downloadUrl = endpoints.alumniDetail.studentdownloadExcelCr;

    }
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [triggerApiCall, setTriggerApiCall] = useState(true);

    let url = `${getApi}?page=${page}&limit=10`;

    if (searchTerm) {
        url += `&searchTerm=${searchTerm}`;
        downloadUrl += `?searchTerm=${searchTerm}`;
    }
    const fetchUtils = useAxiosGet(url, { preventCall: !triggerApiCall });

    const handleNext = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handlePrevious = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };

    // const fetchUtils = useAxiosGet(getApi);

    // const fetchUtils = useAxiosGet();
    const { state } = useLocation();

    const yesOrNo = {
        0: "No",
        1: "Yes",
    };

    const options = { month: "long", day: "numeric" };
    const cellModifier = {
        DateOfBirth: ({ value }) => {
            if (!value) return "";
            const [month, day] = value.split("-").map(Number);
            const date = new Date();
            date.setMonth(month - 1);
            date.setDate(day);
            return date.toLocaleDateString("en-US", options);
        },
        IsEmployed: ({ value }) => yesOrNo[value] ?? value,
        IsActive: ({ value }) => {
            const status = {
                1: "Active",
                2: "Inactive",
                3: "Deactivated",
            };
            return status[value];
        },
        courses: ({ row }) => {
            // if (['STUDENTCOUNCIL'].includes(user.Role)) {
            //     return row?.original?.COURSE_NAME || '';
            // }
            let batchDetails = [];
            if (row?.original?.batch) {
                batchDetails = row?.original?.batch;
            } else {
                batchDetails = row?.original?.BATCH_CODE;
            }
            return batchDetails.map((report) => report.COURSE_NAME).join(", ");
        },
        user_Type: ({ value }) => {
            const status = {
                1: "Alumni",
                2: "Student",
                3: "Faculty",
            };
            return status[value];
        },
        isBloodDonor: ({ value }) => {
            const status = {
                0: "NO",
                1: "YES",
            };
            return status[value];
        },
        IsEntrepreneur: ({ value }) => yesOrNo[value] ?? value,
        IsLookingForAChange: ({ value }) => yesOrNo[value] ?? value,
        PrivacySettings: ({ value }) => {
            switch (Number(value)) {
                case 1:
                    return "Full Access";
                case 2:
                    return "No Access";
                case 3:
                    return "Limited Access";

                default:
                    return value;
            }
        },
        bloodGroup: ({ value }) => {
            const BLOOD_GROUPS = {
                1: "A +ve",
                2: "A -ve",
                3: "B +ve",
                4: "B -ve",
                5: "AB +ve",
                6: "AB -ve",
                7: "O +ve",
                8: "O -ve",
            };
            return BLOOD_GROUPS[value] || "Not Disclosed";
        },
    };

    let students = [];
    if (fetchUtils.response && fetchUtils.response.responseData) {
        students = objCloneKeyValue(fetchUtils.response.responseData) || [];

        if (!fetchUtils.loading) {
            students = students.map((data) => {
                if (Array.isArray(data.BATCH_CODE)) {
                    // Handle the case where BATCH_CODE is an array of objects
                    data.batch = data.BATCH_CODE;
                    data.BATCH_CODE = data.BATCH_CODE[0].BATCH_CODE; // Get the first BATCH_CODE
                    data.BATCH_NAME = data.batch[0].BATCH_NAME; // Get the BATCH_NAME from the first batch
                    data.courses = data.batch[0].COURSE_NAME;
                }
                return data;
            });

            // If the user is a class representative, filter students by their batch
            // if (user.Role?.toLowerCase() === 'classrepresentative') {
            //     students = students.filter((item) => {
            //         // Ensure that batch is defined and match the class representative's batch
            //         return item.batch && item.batch[0].BATCH_CODE === user.DELEGATE_BATCH_CODE;
            //     });
            // }
        }
    }

    return (
        <>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                endpoints={endpoints.alumniDetail}
                Form={StudentForm}
                formSize="lg"
                {...viewProps.StudentDetails}
                relevants={[...(viewProps.StudentDetails.relevants || [])]}
                columnHeads={[
                    ...(viewProps.StudentDetails.columnHeads || []),
                    //     ...(![
                    //         'STUDENTCOUNCIL',
                    //         'CLASSREPRESENTATIVE',
                    //         'CLASSREP + STUDENT COUNCIL',
                    //     ].includes(user.Role)
                    //         ? ['Delete']
                    //         : []),
                ]}
                {...fetchUtils}
                response={students}
                name={`Student Details`}
                cellModifier={cellModifier}
                addBtnLabel={`Add Student`}
                headerExtras={
                    <>
                        <BulkUpload
                            urlObjects={{
                                templateUrl:
                                    endpoints.alumniDetail.bulkUploadTemplate,
                                fileUpload:
                                    endpoints.alumniBulkUpload.fileUpload,
                                validateFile:
                                    endpoints.alumniBulkUpload.validateFile,
                                initialBulkInsert:
                                    endpoints.alumniBulkUpload
                                        .initialBulkInsert,
                                bulkUploadConfirm:
                                    endpoints.alumniBulkUpload
                                        .bulkUploadConfirm,
                            }}
                            filename="Student Details"
                            afterUpload={fetchUtils.reFetch}
                            templateExtension=".xlsx"
                            errorObjects={errorObjects}
                        />
                        <DownloadDataAsExcel
                            filename={"Student Details"}
                            apiEndpoint={downloadUrl}
                        />
                    </>
                }
                initialFilterValue={state || ""}
                irremovable
                // errorObjects={errorObjects}
                downloadable={false}
                showSearch={false}
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm}
                        setSearchTerm={handleSearch}
                        placeholder="Search Student by name"
                    />
                }
            />
        </>
    );
}

export default StudentDetails;
