import React from 'react';
import { Col } from 'react-bootstrap';
import endpoints from '../../endpoints';
import BloodDonor from './BloodDonor';
import { ReactComponent as Blood } from '../../asset/icons/Blood.svg';
import { useAxiosGet } from '../../hooks/axiosHooks';
import Loader from '../../components/Loader';

function EntrepreneurBloodDonor() {
    const { response: data, loading } = useAxiosGet(endpoints.statistics.bloodDonorCr);
    const alumniDetails = data.responseData

    if (loading) return <Loader />;
    return (
        <>
            {' '}
            <Col className='d-flex mb-1'>
                <div
                    style={{
                        width: '45px',
                        height: '45px',
                        borderRadius: '50%',
                        background: '#EFEFEF',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Blood />
                </div>

                <Col className='px-2'>
                    <div className='small h6 text-dark'>Blood Donors</div>

                    <div
                        className='text-muted my-1'
                        style={{ fontSize: '.75em' }}
                    >
                        Based on Batch
                    </div>
                </Col>
            </Col>
            {alumniDetails?.length > 0 ? (
                <BloodDonor bloodDonor={alumniDetails} />
            ) : (
                'No Blood Donors'
            )}
        </>
    );
}

export default EntrepreneurBloodDonor;
