import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import FormikControl from '../../components/formikControl/FormikControl';
import withAlert from '../../components/withAlert';
import Loader from '../../components/Loader';
import FormSubmissionBtn from '../../components/FormSubmissionBtn';
import extractErrorFromRes from '../../helpers/extractErrorFromRes';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';

function GoverningCouncilForm({
    endpoint,
    onCancel,
    onAfterSubmit,
    showAlert,
    updateValues,
}) {
    // const { response: alumnis, loading } = useAxiosGet(
    //     endpoints.alumniDetail.getAlumniDropDownList
    // );
    // const alumni = alumnis.responseData


    const submitHandler = (values, { setSubmitting }) => {
        const body = { ...values };
        body.CityChapter_ID = '1';

        axios
            .post(endpoint, body)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                showAlert(
                    'error',
                    err.response.data?.statusText || 'Something went wrong'
                );
            })
            .finally((res) => setSubmitting(false));
    };

    const initialFormValues = updateValues || {
        EC_Member_Alumni_ID: '',
        EC_Member_Designation: '',
        EC_Member_Elected_Date: '',
    };
    const preSelectedOption = {};

    if (updateValues) {
        preSelectedOption.alumni = { EC_Member_Alumni_ID: updateValues.EC_Member_Alumni_ID, ALUMNI_Name: updateValues.EC_Member_Name }
    }
    // if (loading) {
    //     return <Loader />;
    // }
    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                EC_Member_Alumni_ID: Yup.string().required(),
                EC_Member_Designation: Yup.string().required(),
                EC_Member_Elected_Date: Yup.date()
                    .max(Date(), 'Cannot be a future date')
                    .required(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting }) => (
                <Form>
                    {!updateValues ?
                        <FormikControl
                            control='PaginatedSelect'
                            required
                            name='EC_Member_Alumni_ID'
                            label='Member'
                            // options={alumni}
                            endpoint={endpoints.alumniDetail.getAlumniDropDownList}
                            optionValue='ALUMNI_BATCH_ADM_CODE'
                            defaultValue={preSelectedOption.alumni}
                            optionLabel='ALUMNI_Name'
                            disabled={updateValues}
                        />
                        : <FormikControl
                            control='select-advanced'
                            required
                            name='EC_Member_Alumni_ID'
                            label='Member'
                            optionValue='ALUMNI_BATCH_ADM_CODE'
                            defaultValue={preSelectedOption.alumni}
                            optionLabel='ALUMNI_Name'
                            disabled={updateValues}
                        />}
                    <FormikControl
                        control='input'
                        required
                        name='EC_Member_Designation'
                        label='Member Designation'
                    />
                    <FormikControl
                        control='input'
                        type='date'
                        required
                        name='EC_Member_Elected_Date'
                        label='Member Elected Date'
                    />

                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(GoverningCouncilForm);
