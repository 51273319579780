import React from 'react';
import { Card } from 'react-bootstrap';

function Chip({ bg, title, color, children }) {
    return (
        <Card
            bg='transparent'
            className='text-center border-0 mb-3'
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
        >
            <div
                className='small h6'
                style={{ textAlign: 'left', fontSize: '14px', color: color }}
            >
                {title.toUpperCase()}
            </div>
            <div
                className=' fs-3 my-3 card-shadow'
                style={{
                    background: bg,
                    borderRadius: ' 20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    // marginRight: '30px',
                    marginLeft: '0',
                    color: '#272727E5',
                    padding: '12%',
                    border: '1px solid #E3E3E3',
                    width: '90%'
                }}
            >
                {children}
            </div>
        </Card>
    );
}

export default Chip;
