import axios from 'axios';
import { useState } from 'react';
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
import AdminImg from '../../asset/icons/AdminImg.png';
import endpoints from '../../endpoints';
import extractErrorFromRes from '../../helpers/extractErrorFromRes';
import ModalWrapper from '../ModalWrapper';
import PasswordInput from '../PasswordInput';
import withAlert from '../withAlert';
import Loader from '../Loader';
const passwordRegex =
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
function Profile({ user, showAlert }) {
    const [password, setPassword] = useState({
        old: '',
        new: '',
        confirmNew: '',
    });
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState('');

    const onSubmit = (e, close) => {
        e.preventDefault();
        setSubmitting(true);

        if (password.new !== password.confirmNew) {
            showAlert(
                'error',
                'New password and confirm password is not matching'
            );

            setSubmitting(false);
            return '';
        }

        if (password.old === password.confirmNew) {
            showAlert(
                'error',
                'New password cannot be the same as the current password'
            );
            setSubmitting(false);
            return '';
        }

        const body = {
            OldPassword: password.old,
            NewPassword: password.new,
        };
        axios
            .post(endpoints.authentication.changePassword, body)
            .then((res) => {
                showAlert('success', 'Password Change Successfully');

                close();
            })
            .catch((err) => {
                showAlert('error', extractErrorFromRes(err));
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleChange = (e) => {
        const { value, name } = e.target;
        if (name === 'new') {
            if (/\s/.test(value)) {
                setError('Space is not allowed');
            } else if (!passwordRegex.test(value)) {
                setError(
                    'Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a digit, and a special character'
                );
            } else {
                setError('');
            }
        }

        setPassword({
            ...password,
            [name]: value,
        });
    };

    return (
        <div className='custom-dropdown'>
            <Dropdown align='end'>
                <Dropdown.Toggle
                    variant='transparent'
                    className='primaryBtn shadow-sm p-2  ms-4 '
                    style={{ padding: '0' }}
                >
                    <Row className='g-0'>
                        <Col
                            sm='auto'
                            className='p-0'
                        >
                            <img
                                src={AdminImg}
                                loading='lazy'
                                alt='Logged in Person'
                                height={35}
                                className='me-auto'
                            />
                        </Col>
                        <Col
                            sm='auto'
                            className='ps-1 pe-2'
                            style={{ marginTop: '-4px' }}
                        >
                            <small>{user.ALUMNI_Name || 'Username'}</small>
                            <br />
                            <span
                                className='d-block text-black-50'
                                style={{
                                    lineHeight: '.7',
                                    fontSize: '.72em',
                                }}
                            >
                                {user.Role}
                            </span>
                        </Col>
                    </Row>
                </Dropdown.Toggle>
                <Dropdown.Menu className='border-0 shadow-sm p-3'>
                    <ModalWrapper
                        modalAttrs={{
                            size: 'sm',
                            onExited: () => {
                                setPassword({
                                    old: '',
                                    new: '',
                                    confirmNew: '',
                                });
                                setError('');
                            },
                        }}
                        modalTitle='Change Password'
                        renderModalBody={(closeModal) => (
                            <Form onSubmit={(e) => onSubmit(e, closeModal)}>
                                <div className='mb-4'>
                                    <PasswordInput
                                        placeholder='Old Password'
                                        className='border shadow-none rounded'
                                        name='old'
                                        value={password.old}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className='mb-4'>
                                    <PasswordInput
                                        placeholder='New Password'
                                        className='border shadow-none rounded'
                                        name='new'
                                        value={password.new}
                                        onChange={handleChange}
                                    />
                                    <div
                                        style={{
                                            fontSize: '12px',
                                            color: 'red',
                                            textAlign: 'justify',
                                        }}
                                    >
                                        {error}
                                    </div>
                                </div>

                                <div className='mb-5'>
                                    <PasswordInput
                                        placeholder='Confirm new password'
                                        className='border shadow-none rounded'
                                        name='confirmNew'
                                        value={password.confirmNew}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className='d-flex justify-content-center'>
                                    <Button
                                        size='sm'
                                        disabled={
                                            submitting ||
                                            !password.old ||
                                            !password.confirmNew ||
                                            !password.new ||
                                            error
                                        }
                                        type='submit'
                                    >
                                        {submitting ? 'Saving' : 'Change'}
                                    </Button>
                                    <Button
                                        size='sm'
                                        variant='secondary'
                                        className='ms-3'
                                        onClick={closeModal}
                                        disabled={submitting}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                                {submitting && <Loader />}
                            </Form>
                        )}
                    >
                        <Dropdown.Item>
                            <span className='small'>Change Password</span>
                        </Dropdown.Item>
                    </ModalWrapper>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

export default withAlert(Profile);
