import React, { useState } from 'react';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import FacultyPersonalTagForm from './FacultyPersonalTagForm';
import { useAuthenticationState } from '../../context/Auth.context';
import PaginatedTable from '../../components/table/PaginatedTable';
import GlobalSearch from '../../components/GlobalSearch';
import DownloadDataAsExcel from '../../components/DownloadDataAsExcel';

function FacultyPersonalTag() {
    const { user } = useAuthenticationState();
    const [page, setPage] = useState(1);
    const [triggerApiCall, setTriggerApiCall] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    let url = `${endpoints.alumniPersonalTag.get}faculty?page=${page}&limit=10`;
    let downloadUrl = `${endpoints.alumniPersonalTag.downloadExcel}?tagRelatedTo=faculty`

    if (searchTerm) {
        url = `${endpoints.alumniPersonalTag.get}faculty?searchTerm=${searchTerm}&page=${page}&limit=10`;
        downloadUrl += `&searchTerm=${searchTerm}`
    }

    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };


    const fetchUtils = useAxiosGet(url, {
        preventCall: !triggerApiCall || searchTerm,
    });
    const fetchUtilsWithFilteredData = {
        ...fetchUtils
    };
    const handleNext = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handlePrevious = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const cellModifier = {
        alumni_personal_tags: ({ value }) => {
            // Split the comma-separated string into an array
            const uniqueTags = value ? value?.split(',') : [];

            return (
                <div
                    style={{
                        maxWidth: '500px',
                        overflow: 'auto',
                        paddingBottom: '5px',
                    }}
                >
                    {uniqueTags?.map((tagName) => (
                        <div
                            key={tagName.trim()} // Use trimmed value as key to avoid extra spaces
                            className="p-2 me-2 d-inline-block small"
                            style={{
                                background: 'rgba(95, 179, 246, 0.1)',
                                borderRadius: '10px',
                                border: '1px solid #e6eced',
                                boxShadow: "3px 3px 2px rgba(0, 0, 0, 0.4)",
                            }}
                        >
                            {tagName?.trim()} {/* Display the trimmed tag */}
                        </div>
                    ))}
                </div>
            );
        },
    };

    return (
        <>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                endpoints={endpoints.alumniPersonalTag}
                Form={FacultyPersonalTagForm}
                {...viewProps.FacultyPersonalTag}
                cellModifier={cellModifier}
                addBtnLabel='Add Mapping'
                showSearch={false}
                formSize='md'
                irremovable
                immutable={[
                    'STUDENTCOUNCIL',
                    'CLASSREPRESENTATIVE',
                    'CLASSREP + STUDENT COUNCIL',
                ].includes(user.Role)}
                insertable={
                    ![
                        'STUDENTCOUNCIL',
                        'CLASSREPRESENTATIVE',
                        'CLASSREP + STUDENT COUNCIL',
                    ].includes(user.Role)
                }
                // {...fetchUtils}
                {...fetchUtilsWithFilteredData}
                response={fetchUtils.response.responseData}
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm}
                        setSearchTerm={handleSearch}
                        placeholder="Search by faculty name"
                    />
                }
                downloadable={false}
                headerExtras={
                    <DownloadDataAsExcel
                        filename={"Faculty Personal Tag Mapping Details"}
                        apiEndpoint={downloadUrl}
                    />
                }
            />
        </>
    );
}

export default FacultyPersonalTag;
