import React, { useState } from "react";
import exportToExcel from "../helpers/exportToExcel";
import axios from "axios";
import { ReactComponent as Download } from "../asset/icons/Download.svg";
import Loader from "./Loader";
import toast from "react-hot-toast";
import { Button } from "react-bootstrap";

const DownloadDataAsExcel = ({
    apiEndpoint,
    filename = "data",
    buttonTitle,
}) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const [progress, setProgress] = useState(0); // New state to hold progress

    const handleDownload = async () => {
        try {
            // Make an API call to fetch the data
            setIsDownloading(true);
            const response = await axios.get(apiEndpoint, {
                onDownloadProgress: (progressEvent) => {
                    // Calculate progress
                    const percentage = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setProgress(percentage);
                },
            });

            const jsonData = response.data.responseData;
            if (jsonData.length > 0) {
                const today = new Date();
                const options = {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hour12: true,
                };
                const formattedDate = today.toLocaleString("en-IN", options);

                exportToExcel(
                    jsonData,
                    filename + "_" + formattedDate + ".xlsx"
                );
                setTimeout(() => {
                    setIsDownloading(false);
                    setProgress(0); // Reset progress
                }, 1000);
                toast.success('Download Complete');
            } else {
                toast.error("No users left to generate the Excel file.");
                setIsDownloading(false);
                setProgress(0); // Reset progress if no data
            }
        } catch (error) {
            toast.error("Download Failed");
            setIsDownloading(false);
            setProgress(0); // Reset progress in case of error
        }
    };

    return (
        <>
            {isDownloading && <Loader type='progress' progress={progress} description="Don't go back or refresh while the Excel file is downloading." />} {/* Pass progress to Loader */}
            <Button
                className="primaryBtn btnAnime ms-4 px-3 py-1"
                onClick={handleDownload}
                title={
                    buttonTitle ||
                    "Download " + filename.replace(/-/g, " ") + " in Excel"
                }
            >
                <Download />
            </Button>
        </>
    );
};

export default DownloadDataAsExcel;
