import React, { useState } from 'react';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import { useLocation } from 'react-router-dom';
import Chip from './Chip';
import PaginatedTable from '../../components/table/PaginatedTable';
import GlobalSearch from '../../components/GlobalSearch';
import DownloadDataAsExcel from '../../components/DownloadDataAsExcel';

function RSVP() {
    const { state } = useLocation();

    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    let url = `${endpoints.rsvp.get + state.Event_CALENDAR_ID}?page=${page}&limit=10`;
    let downloadUrl = `${endpoints.rsvp.downloadExcel}/${state.Event_CALENDAR_ID}`

    if (searchTerm) {
        url = `${endpoints.rsvp.get + state.Event_CALENDAR_ID}?searchTerm=${searchTerm}&page=${page}&limit=10`;
        downloadUrl += `?searchTerm=${searchTerm}`
    }



    const fetchUtils = useAxiosGet(url,
    );

    const handleNext = (page) => {
        setPage(page);
    };

    const handlePrevious = (page) => {
        setPage(page);
    };
    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };
    const cellModifier = {
        IsEmployed: ({ value }) => {
            const types = {
                0: 'No',
                1: 'Yes',
            };
            return types[value];
        },
        IsActive: ({ value }) => {
            const types = {
                0: 'No',
                1: 'Yes',
            };
            return types[value];
        },
        IsEntrepreneur: ({ value }) => {
            const types = {
                0: 'No',
                1: 'Yes',
            };
            return types[value];
        },
        IsLookingForAChange: ({ value }) => {
            const types = {
                0: 'No',
                1: 'Yes',
            };
            return types[value];
        },
        RSVP_STATUS: ({ value }) => {
            const types = {
                3: 'Not Interested',
                1: 'Interested',
                2: 'Maybe',
            };
            return types[value];
        },
    };

    const { responseData: response, counts } = fetchUtils.response;
    let not_intrested_count = counts?.not_intrested_count;
    let intrested_count = counts?.intrested_count;
    let may_be_count = counts?.may_be_count;

    const data = {
        items: response,
        not_intrested_count: not_intrested_count,
        intrested_count: intrested_count,
        may_be_count: may_be_count,
        total_responses: counts?.total_responses,
    };

    return (
        <>
            <div
                style={{
                    fontWeight: 500,
                    fontSize: '16px',
                    paddingBottom: '10px',
                }}
            >
                {state.Event_CALENDAR_EVENT_NAME}
            </div>
            <div
                style={{
                    color: '#6C757D',
                    fontSize: '12px',
                    fontWeight: 500,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                RSVP :
                <div
                    style={{
                        color: '#6C757D',
                        fontSize: '12px',
                        fontWeight: 500,
                        paddingLeft: '4px',
                    }}
                >
                    Overall {data.total_responses ?? 0} User's response
                </div>
            </div>

            <div style={{ paddingTop: '1%', width: '58%', display: 'flex', marginLeft: '-10px' }}>
                <div style={{ width: '30%' }}>
                    <Chip bg='#F9FFF4' title='Interested' color='#0fc202'>
                        {data?.intrested_count ?? 0}
                    </Chip>
                </div>
                <div style={{ width: '30%' }}>
                    <Chip bg='#FFF4F4' title='Not Interested' color='#cf0205'>
                        {data?.not_intrested_count ?? 0}
                    </Chip>
                </div>
                <div style={{ width: '30%' }}>
                    <Chip bg='#FFFDF4' title='Maybe' color='#e3700b'>
                        {data?.may_be_count ?? 0}
                    </Chip>
                </div>
            </div>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                {...viewProps.RSVP}
                cellModifier={cellModifier}
                insertable={false}
                immutable
                irremovable
                {...fetchUtils}
                response={fetchUtils.response.responseData}
                showSearch={false}
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm}
                        setSearchTerm={handleSearch}
                        placeholder="Search by name"
                    />
                }
                downloadable={false}
                headerExtras={
                    <DownloadDataAsExcel
                        filename={"Event response Details"}
                        apiEndpoint={downloadUrl}
                    />
                }
            />
        </>
    );
}

export default RSVP;
