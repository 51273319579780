import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import FormikControl from '../../components/formikControl/FormikControl';
import withAlert from '../../components/withAlert';
import Loader from '../../components/Loader';
import FormSubmissionBtn from '../../components/FormSubmissionBtn';
import extractErrorFromRes from '../../helpers/extractErrorFromRes';
import { useAxiosGetMultiple } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import { checkIsoAndReturnYMDFormat } from '../../helpers/dateFunctions';
import { Col, Row } from 'react-bootstrap';
import { useLabelProvider } from '../../context/LabelProvider';
import { useAuthenticationState } from '../../context/Auth.context';

function MarkAsSafeForm({
    endpoint,
    onCancel,
    onAfterSubmit,
    showAlert,
    updateValues,
}) {
    const { setLabels } = useLabelProvider();
    const { user } = useAuthenticationState();

    useEffect(() => {
        setLabels('MarkAsSafe');
    }, []);

    const { response, loading } = useAxiosGetMultiple({
        cityChapters: endpoints.cityChapters.get,
        batch: endpoints.batch.scBatch,
    });
    const { batch } = response;

    const submitHandler = (values, { setSubmitting }) => {
        const body = { ...values };

        for (const key in body) {
            if (body[key] === 0) {
                body[key] = '0';
            }
        }

        axios
            .post(endpoint, body)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                showAlert(
                    'error',
                    err.response.data?.statusText || 'Something went wrong'
                );
            })
            .finally((res) => setSubmitting(false));
    };

    if (updateValues) {
        updateValues.Alumni_MarkAsSafe_BROADCAST_TYPE =
            updateValues.Alumni_MarkAsSafe_BROADCAST_TYPE_ID;
        updateValues.Alumni_MarkAsSafe_EventTYPE =
            updateValues.Alumni_MarkAsSafe_EventTYPE_ID;
        updateValues.Alumni_MarkAsSafe_STATUS =
            updateValues.Alumni_MarkAsSafe_STATUS_ID;
    }
    const initialFormValues = updateValues || {
        Alumni_MarkAsSafe_EventName: '',
        Alumni_MarkAsSafe_EventTYPE: '',
        Alumni_MarkAsSafe_STATUS: '',
        Alumni_MarkAsSafe_BROADCAST_TYPE: '',
        BATCH_Array: [],
        Alumni_MarkAsSafe_CITYCHAPTER_CODE: '',
        Alumni_MarkAsSafe_EventStartDate: '',
        Alumni_MarkAsSafe_EventEndDate: '',
    };

    const eventType = {
        FLOOD: 1,
        EPIDEMIC: 2,
        'POLITICAL UNREST': 3,
        OTHERS: 4,
    };
    const eventStatus = {
        CANCELLED: 0,
        ACTIVE: 1,
        EXPIRED: 2,
        RESPONDED: 3,
        CLOSED: 4,
    };
    const preSelectedOption = {};
    if (updateValues) {
        // preSelectedOption.batch = batches.find(
        //     (batch) =>
        //         Number(batch.BATCH_CODE) ===
        // Number(initialFormValues.BATCH_Array);
        // );
        // initialFormValues.BATCH_Array = [{
        //     BATCH_CODE: updateValues.Alumni_MarkAsSafe_BATCH_CODE,
        //     BATCH_NAME: updateValues.BATCH_NAME
        // }]
        initialFormValues.BATCH_Array = updateValues.Alumni_MarkAsSafe_BATCH_CODE
        preSelectedOption.batch = {
            BATCH_CODE: updateValues.Alumni_MarkAsSafe_BATCH_CODE,
            BATCH_NAME: updateValues.BATCH_NAME
        }
        updateValues.Alumni_MarkAsSafe_EventStartDate =
            checkIsoAndReturnYMDFormat(
                updateValues.Alumni_MarkAsSafe_EventStartDate
            );
        updateValues.Alumni_MarkAsSafe_EventEndDate =
            checkIsoAndReturnYMDFormat(
                updateValues.Alumni_MarkAsSafe_EventEndDate
            );
        if (updateValues.BATCH_Array === 'N/A' || updateValues.BATCH_NAME === 'N/A') {
            updateValues.BATCH_Array = '';
            initialFormValues.BATCH_Array = null;
            preSelectedOption.batch = null

        }

        if (loading) {
            return <Loader />;
        }
    }
    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                Alumni_MarkAsSafe_EventName: Yup.string().required(),
                Alumni_MarkAsSafe_EventTYPE: Yup.string().required(),
                Alumni_MarkAsSafe_STATUS: Yup.string().required(),
                Alumni_MarkAsSafe_BROADCAST_TYPE: Yup.string().required(),
                BATCH_Array: !updateValues ? Yup.array().of(
                    Yup.string().when('Alumni_MarkAsSafe_BROADCAST_TYPE', {
                        is: '0',
                        then: Yup.string().nullable(),
                        otherwise: Yup.array()
                            .of(Yup.string().required()) // Validate each entry as a required string
                            .min(1, 'At least one batch is required'),
                    })
                ) :
                    Yup.string().when('Alumni_MarkAsSafe_BROADCAST_TYPE', {
                        is: '0',
                        then: Yup.string().nullable(),
                        otherwise:
                            Yup.string().required()
                    }),

                Alumni_MarkAsSafe_EventStartDate: Yup.date()
                    .max(Date(), "Can't be beyond today")
                    .required(),
                Alumni_MarkAsSafe_EventEndDate: Yup.date()
                    .min(
                        Yup.ref('Alumni_MarkAsSafe_EventStartDate'),
                        'End Date must be later than Start Date'
                    )
                    .max(Date(), "Can't be beyond today")
                    .nullable(),
            })}
            onSubmit={submitHandler}
        >
            {({ isSubmitting, values, setFieldValue, errors }) => (
                <Form>
                    <Row>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                required
                                name='Alumni_MarkAsSafe_EventName'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='select'
                                required
                                name='Alumni_MarkAsSafe_EventTYPE'
                            >
                                <option value=''>Select Event Type</option>
                                {Object.keys(eventType).map((type) => (
                                    <option key={type} value={eventType[type]}>
                                        {type}
                                    </option>
                                ))}
                            </FormikControl>
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='select'
                                required
                                name='Alumni_MarkAsSafe_STATUS'
                            >
                                <option>Select Event Status</option>
                                {Object.keys(eventStatus).map((type) => (
                                    <option
                                        key={type}
                                        value={eventStatus[type]}
                                    >
                                        {type}
                                    </option>
                                ))}
                            </FormikControl>
                        </Col>

                        <Col md='4'>
                            <FormikControl
                                control='check'
                                type='radio'
                                required
                                options={[
                                    { key: 'All', value: '0' },
                                    { key: 'Batch', value: '2' },
                                ]}
                                name='Alumni_MarkAsSafe_BROADCAST_TYPE'
                                onChange={(e) => {
                                    setFieldValue(
                                        'Alumni_MarkAsSafe_BROADCAST_TYPE',
                                        e.target.value
                                    );

                                    !updateValues ? setFieldValue('BATCH_Array', []) : setFieldValue('BATCH_Array', '')
                                }}
                            />
                        </Col>

                        {Number(values.Alumni_MarkAsSafe_BROADCAST_TYPE) !==
                            0 && (
                                <>
                                    <Col sm='6'>
                                        <FormikControl
                                            control='select-advanced'
                                            required
                                            options={batch}
                                            defaultValue={preSelectedOption.batch}
                                            optionValue='BATCH_CODE'
                                            optionLabel='BATCH_NAME'
                                            name='BATCH_Array'
                                            label='Batch'
                                            isMulti={!updateValues}
                                        />
                                    </Col>
                                </>
                            )}

                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='date'
                                required
                                name='Alumni_MarkAsSafe_EventStartDate'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                // required
                                type='date'
                                name='Alumni_MarkAsSafe_EventEndDate'
                            />
                        </Col>
                    </Row>
                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(MarkAsSafeForm);
