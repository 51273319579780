import React, { useState } from 'react';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import { Link } from 'react-router-dom';
import MarkAsSafeForm from './MarkAsSafeForm';
import PaginatedTable from '../../components/table/PaginatedTable';
import GlobalSearch from '../../components/GlobalSearch';
import DownloadDataAsExcel from '../../components/DownloadDataAsExcel';

function MarkAsSafe() {
    let getApi = endpoints.adminMarkAsSafe.get;
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");

    let url = `${getApi}?page=${page}&limit=10`;
    let downloadUrl = `${endpoints.markAsSafe.downloadExcel}`

    if (searchTerm) {
        url = `${getApi}?searchTerm=${searchTerm}&page=${page}&limit=10`;
        downloadUrl += `?searchTerm=${searchTerm}`
    }
    const fetchUtils = useAxiosGet(url);
    const handleNext = (page) => {
        setPage(page);
    };

    const handlePrevious = (page) => {
        setPage(page);
    };
    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };
    const cellModifier = {
        Response: ({ row }) => (
            <Link
                to={'/safety-response'}
                state={row.original.Alumni_MarkAsSafe_EventID}
            >
                User Responses
            </Link>
        ),
        Alumni_MarkAsSafe_EventStartDate: ({ value }) =>
            new Date(value).toDateString(),
        Alumni_MarkAsSafe_EventEndDate: ({ value }) => {
            if (value) {
                return new Date(value).toDateString();
            } else {
                return value;
            }
        },
    };
    return (
        <>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                endpoints={endpoints.markAsSafe}
                Form={MarkAsSafeForm}
                {...viewProps.MarkAsSafe}
                cellModifier={cellModifier}
                addBtnLabel='Add Event'
                formSize='md'
                {...fetchUtils}
                response={fetchUtils.response.responseData}
                showSearch={false}
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm}
                        setSearchTerm={handleSearch}
                        placeholder="Search..."
                    />
                }
                downloadable={false}
                headerExtras={
                    <DownloadDataAsExcel
                        filename={"Mark As Safe Event response Details"}
                        apiEndpoint={downloadUrl}
                    />
                }
            />
        </>
    );
}

export default MarkAsSafe;
