import React, {useState} from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import { ReactComponent as Approve } from '../../asset/icons/Approve.svg';
import { ReactComponent as Reject } from '../../asset/icons/Block.svg';
import ModalWrapper from '../../components/ModalWrapper';
import PostWithWarning from '../../components/PostWithWarning';
import { useAuthenticationState } from '../../context/Auth.context';
import StatusIndicator from '../../components/StatusIndicator';
import withAlert from '../../components/withAlert';
import Form from './AnnouncementForm';
import DownloadDataAsExcel from '../../components/DownloadDataAsExcel';
import GlobalSearch from '../../components/GlobalSearch';
import PaginatedTable from '../../components/table/PaginatedTable';

function Announcements({ showAlert }) {
    const { user } = useAuthenticationState();

    let endpoint;
    let downloadUrl;
    const getRoleCategory = (role) => {
        if (['DELEGATE', 'DELEGATE + CCH'].includes(role)) {
            return 'alumni';
        } else if (
            [
                'STUDENTCOUNCIL',
                'CLASSREPRESENTATIVE',
                'CLASSREP + STUDENT COUNCIL',
            ].includes(role)
        ) {
            return 'student';
        } else {
            return 'others';
        }
    };

    switch (getRoleCategory(user.Role)) {
        case 'alumni':
            endpoint = endpoints.announcements;
            downloadUrl = endpoints.announcements.downloadExcel;
            break;
        case 'student':
            endpoint = endpoints.studentAnnouncements;
            downloadUrl = endpoints.studentAnnouncements.downloadExcel;
            break;
        default:
            endpoint = endpoints.announcements;
            downloadUrl = endpoints.announcements.downloadExcel;
            break;
    }

    // const { response, ...fetchUtils } = useAxiosGet(endpoint.get);

    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [triggerApiCall, setTriggerApiCall] = useState(true);

    let url = `${endpoint.get}?page=${page}&limit=10`;
    
    if (searchTerm) {
        url += `&searchTerm=${searchTerm}`;
        downloadUrl += `?searchTerm=${searchTerm}`;
    }
    const fetchUtils = useAxiosGet(url, { preventCall: !triggerApiCall });

    const handleNext = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handlePrevious = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    
    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };


    // let announcements = [...fetchUtils.response?.pagination];
    // if (
    //     [
    //         'DELEGATE',
    //         'DELEGATE + CCH',
    //         // 'STUDENTCOUNCIL',
    //         // 'CLASSREPRESENTATIVE',
    //         // 'CLASSREP + STUDENT COUNCIL',
    //     ].includes(user.Role)
    // ) {
    //     announcements = announcements.filter(
    //         (ann) =>
    //             Number(ann.INSTITUTION_ID) ===
    //             Number(user.INSTITUTION_ID)
    //     );
    // }
    const cellModifier = {
        BATCH_NAME: ({ value }) => (value === 'N/A' ? 'ALL' : value),
        Approve: ({ row }) => (
            <ModalWrapper
                modalTitle={<span>Approve Announcement?</span>}
                modalAttrs={{ size: 'sm' }}
                disabled={Number(row.original.Alumni_AnnouncmentReq_RESPONSE_ID) !== 3 || row.original.is_deleted_from_mobile}
                renderModalBody={(closeModal) => (
                    <PostWithWarning
                        onSuccess={() => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert(
                                'success',
                                'The announcement has been approved'
                            );
                        }}
                        onCancel={closeModal}
                        onError={(err) => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert(
                                'error',
                                `${err.response.data.statusText} You can't approve this announcement.` || 'The announcement has been removed'
                            );
                        }}
                        endpoint={endpoints.announcements.respond}
                        configBody={{
                            Alumni_AnnouncmentReq_RESPONSE_COMMENT: 'nil',
                            Alumni_AnnouncmentReq_RESPONSE_ID: '1',
                            Alumni_AnnouncmentReq_ID:
                                row.original.Alumni_AnnouncmentReq_ID,
                            Alumni_AnnouncmentReq_TITLE:
                                row.original.Alumni_AnnouncmentReq_TITLE,
                            NOTIFICATION_CONTENT: 'Announcement Approved',
                            CREATEDBY: row.original.CREATEDBY,
                        }}
                        successVarient='success'
                        dangerVarient='danger'
                    />
                )}
            >
                <span type='button' className='ps-3'>
                    <Approve />
                </span>
            </ModalWrapper>
        ),
        Reject: ({ row }) => (
            <ModalWrapper
                modalTitle={'Block Announcement ?'}
                modalAttrs={{ size: 'sm' }}
                disabled={Number(row.original.Alumni_AnnouncmentReq_RESPONSE_ID) !== 3 || row.original.is_deleted_from_mobile}
                renderModalBody={(closeModal) => (
                    <PostWithWarning
                        onSuccess={() => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert(
                                'success',
                                'The announcement has been rejected'
                            );
                        }}
                        onError={(err) => {
                            fetchUtils.reFetch();
                            closeModal();
                            showAlert(
                                'error',
                                `${err.response.data.statusText} You can't reject this announcement.` || 'The announcement has been removed'
                            );
                        }}
                        onCancel={closeModal}
                        endpoint={endpoints.announcements.respond}
                        configBody={{
                            Alumni_AnnouncmentReq_RESPONSE_COMMENT: 'nil',
                            Alumni_AnnouncmentReq_RESPONSE_ID: '2',
                            Alumni_AnnouncmentReq_ID:
                                row.original.Alumni_AnnouncmentReq_ID,
                            Alumni_AnnouncmentReq_TITLE:
                                row.original.Alumni_AnnouncmentReq_TITLE,
                            NOTIFICATION_CONTENT: 'Announcement Rejected',
                            CREATEDBY: row.original.CREATEDBY,
                        }}
                    // successVarient='success'
                    // dangerVarient='danger'
                    />
                )}
            >
                <span type='button' className='ps-3'>
                    <Reject />
                </span>
            </ModalWrapper>
        ),
        Alumni_AnnouncmentReq_RESPONSE_ID: ({ value }) => {
            const status = {
                1: { tag: 'Approved', variant: 'bg-success' },
                2: { tag: 'Rejected', variant: 'bg-danger' },
                3: { tag: 'Not Responded', variant: 'bg-warning' },
            };
            return (
                <StatusIndicator
                    variant={status[value]?.variant || 'bg-warning'}
                    status={status[value]?.tag || 'Not Responded'}
                />
            );
        },
        is_deleted_from_mobile: ({ value, row }) => {
            const status = {
                1: { tag: `Removed by ${row.original?.ALUMNI_Name}`, variant: 'bg-secondary' },
                0: { tag: 'Not Deleted', variant: 'bg-info' },
            };
            return (
                <StatusIndicator
                    variant={status[value]?.variant || 'bg-warning'}
                    status={status[value]?.tag || 'Not Responded'}
                />
            );
        },
        Alumni_AnnouncmentReq_ExpiryDate: ({ value }) =>
            new Date(value).toLocaleDateString(),
    };

    const replaceAlumniWithStudent = (columns) => {
        return columns.map((column) => column.replace(/Alumni/gi, 'Student'));
    };

    return (
        <>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                endpoints={endpoint}
                name={viewProps.Announcements.name}
                Form={Form}
                addBtnLabel='Add Announcement'
                relevants={[
                    ...(viewProps.Announcements.relevants || []),
                    ...(![
                        'STUDENTCOUNCIL',
                        'CLASSREPRESENTATIVE',
                        'CLASSREP + STUDENT COUNCIL',
                    ].includes(user.Role)
                        ? ['Approve', 'Reject']
                        : []),
                ]}
                columnHeads={[
                    ...(![
                        'STUDENTCOUNCIL',
                        'CLASSREPRESENTATIVE',
                        'CLASSREP + STUDENT COUNCIL',
                    ].includes(user.Role)
                        ? viewProps.Announcements.columnHeads
                        : replaceAlumniWithStudent(
                            viewProps.Announcements.columnHeads
                        ) || []),
                    ...(![
                        'STUDENTCOUNCIL',
                        'CLASSREPRESENTATIVE',
                        'CLASSREP + STUDENT COUNCIL',
                    ].includes(user.Role)
                        ? ['Approve', 'Reject']
                        : []),
                ]}
                cellModifier={cellModifier}
                // irremovable={[
                //     // 'STUDENTCOUNCIL',
                //     'CLASSREPRESENTATIVE',
                //     'CLASSREP + STUDENT COUNCIL',
                // ].includes(user.Role)}
                formSize='md'
                {...fetchUtils}
                response={fetchUtils.response.responseData}
                immutable
                insertable={[
                    'STUDENTCOUNCIL',
                    'CLASSREPRESENTATIVE',
                    'CLASSREP + STUDENT COUNCIL',
                ].includes(user.Role)}
                showSearch={false}
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm}
                        setSearchTerm={handleSearch}
                        placeholder="Search ..."
                    />
                }
                downloadable={false}
                headerExtras={
                    <DownloadDataAsExcel
                        filename={"Announcements"}
                        apiEndpoint={downloadUrl}
                    />
                }
            />
            {/* <CRUDTable
                name={viewProps.Announcements.name}
                
               
                cellModifier={cellModifier}
                // irremovable={[
                //     'STUDENTCOUNCIL',
                //     'CLASSREPRESENTATIVE',
                //     'CLASSREP + STUDENT COUNCIL',
                // ].includes(user.Role)}
                // immutable={
                //     ![
                //         'STUDENTCOUNCIL',
                //         'CLASSREPRESENTATIVE',
                //         'CLASSREP + STUDENT COUNCIL',
                //     ].includes(user.Role)
                // }
                
                {...fetchUtils}
                Form={Form}
                formSize='md'
                response={announcements}
                endpoints={endpoint}
            /> */}
        </>
    );
}

export default withAlert(Announcements);
