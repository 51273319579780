import React, { useState } from 'react';
import PaginatedTable from '../../components/table/PaginatedTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import { Link, useLocation } from 'react-router-dom';
import { useAuthenticationState } from '../../context/Auth.context';
import CourseForm from './CourseForm';
import DownloadDataAsExcel from '../../components/DownloadDataAsExcel';
import GlobalSearch from '../../components/GlobalSearch';


function Courses() {
   
    const { state } = useLocation();
    const { user } = useAuthenticationState();
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState(state || "");
    const [triggerApiCall, setTriggerApiCall] = useState(true);

    // const url = `${endpoints.course.get}/?page=${page}&limit=10`;
    // const fetchUtils = useAxiosGet(endpoints.course.get);
    let url = `${endpoints.course.get}?page=${page}&limit=10`;
    let downloadUrl = endpoints.course.downloadExcel;

    if (searchTerm) {
        url += `&searchTerm=${searchTerm}`;
        downloadUrl += `?searchTerm=${searchTerm}`; 
    }
    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };
    const handleNext = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handlePrevious = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const fetchUtils = useAxiosGet(url, { preventCall: !triggerApiCall });

    
    const cellModifier = {
        CourseWiseAluminiList: ({ row }) => {
            const courseID = row.original.COURSE_ID;
                return (
                    <Link to={'/alumini-map-list'} state={{...row.original,insideID:1}}>
                        Course Wise Alumni List
                    </Link>
                );
            
        },
        COURSE_STATUS: ({ value }) => {
            if (Number(value) === 2) {
                return 'Inactive';
            } else if (Number(value) === 1) {
                return 'Active';
            }
            return 'Inactive';
        },
    };

    return (
        <>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                setTriggerApiCall={setTriggerApiCall}
                endpoints={endpoints.course}
                Form={CourseForm}
                downloadExcelName='Course'
                {...viewProps.Courses}
                cellModifier={cellModifier}
                {...fetchUtils}
                response={
                    fetchUtils.response.responseData
                }
                downloadable={false}
                showSearch={false}
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm || state}
                        setSearchTerm={handleSearch}
                        placeholder="Search..."
                    />
                }
                headerExtras={
                    <>
                        <DownloadDataAsExcel
                            filename={"Course Details"}
                            apiEndpoint={downloadUrl}
                        />
                    </>
                }
            />
        </>
       
    );
}

export default Courses;
