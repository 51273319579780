import React, {useState} from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import { useAuthenticationState } from '../../context/Auth.context';
import PaginatedTable from '../../components/table/PaginatedTable';
import DownloadDataAsExcel from '../../components/DownloadDataAsExcel';
import GlobalSearch from '../../components/GlobalSearch';

function Promos() {
    const { user } = useAuthenticationState();
    // const fetchUtils = useAxiosGet(endpoints.promos.get);

    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [triggerApiCall, setTriggerApiCall] = useState(true);

    let url = `${endpoints.promos.get}?page=${page}&limit=10`;
    let downloadUrl = endpoints.promos.downloadExcel; 

    if (searchTerm) {
        url += `&searchTerm=${searchTerm}`;
        downloadUrl += `?searchTerm=${searchTerm}`;
    }
    const fetchUtils = useAxiosGet(url, { preventCall: !triggerApiCall });

    const handleNext = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handlePrevious = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    
    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };


    const cellModifier = {
        Alumni_PromoCategory: ({ value }) => {
            switch (Number(value)) {
                case 1:
                    return 'Startup';
                case 2:
                    return 'General';
                case 3:
                    return 'Manufacturing';
                case 4:
                    return 'Networking';
                case 5:
                    return 'Financing';

                default:
                    return value;
            }
        },
    };

    return (
        <>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                endpoints={endpoints.promos}
                irremovable
                immutable
                insertable={false}
                name={viewProps.Promos.name}
                relevants={[
                    ...(viewProps.Promos.relevants || []),
                ]}
                columnHeads={[
                    ...(viewProps.Promos.columnHeads||[])
                ]}
                cellModifier={cellModifier}
                // formSize='md'
                {...fetchUtils}
                response={fetchUtils.response.responseData}
                showSearch={false}
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm}
                        setSearchTerm={handleSearch}
                        placeholder="Search ..."
                    />
                }
                downloadable={false}
                headerExtras={
                    <DownloadDataAsExcel
                        filename={"Promos"}
                        apiEndpoint={downloadUrl}
                    />
                }
            />
        </>
    );
}

export default Promos;
