import React, { useState } from 'react';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import CRUDTable from '../../components/table/CRUDTableForSc';
import FilterButtonGroup from './FilterButtonGroup';
import { Col, Nav, Row } from 'react-bootstrap';
import { ReactComponent as Filter } from '../../asset/icons/Filter.svg';
import { ReactComponent as Close } from '../../asset/icons/Close.svg';
import { ReactComponent as Safe } from '../../asset/icons/MarkSafe.svg';


function MarkAsSafeTable() {
    const [page, setPage] = useState(1);
    const [selectedFilter, setSelectedFilter] = useState('');
    const [filterVisible, setFilterVisible] = useState(false);

    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
    };

    const toggleFilterVisibility = () => {
        if (filterVisible) {
            setSelectedFilter('');
        }
        setFilterVisible(!filterVisible);
    };

    const url = `${endpoints.statistics.studentCouncilMarkAsSafe}?page=${page}&limit=5&filter=${selectedFilter}`;
    const fetchUtils = useAxiosGet(url);
    const cellModifier = {
        Alumni_MarkAsSafe_EventStartDate: ({ value }) =>
            new Date(value).toDateString(),
        Alumni_MarkAsSafe_EventEndDate: ({ value }) => {
            if (value) {
                return new Date(value).toDateString();
            } else {
                return value;
            }
        },
        Alumni_MarkAsSafe_Response: ({ value }) => {
            if (value === 'AT RISK') {
                return <span style={{ color: 'red' }}>{value}</span>;
            }
            else if (value === 'SAFE') {
                return <span style={{ color: 'green' }}>{value}</span>
            }
            else {
                return <span style={{ color: 'red' }}>{value}</span>
            }
        },
    };


    const handleNext = (page) => {
        setPage(page);
    };

    const handlePrevious = (page) => {
        setPage(page);
    };

    const filterButtonStyle = {
        backgroundColor: '#467AFF',
        color: 'white',
    };
    return (
        <>
            <CRUDTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                endpoints={endpoints.statistics.studentCouncilMarkAsSafe}
                {...viewProps.DashboardMarkAsSafe}
                cellModifier={cellModifier}
                // addBtnLabel='Add Event'
                formSize='md'
                {...fetchUtils}
                response={fetchUtils.response.responseData}
                irremovable
                immutable
                insertable={false}
                showSearch={false}
                downloadable={false}
                headerExtras={
                    <Row className='px-2' style={{ width: '100%' }}>
                        <Col className='d-flex'>
                            <Safe />
                            <Col className='px-2'>
                                <div className='small h6 text-dark'>
                                    Mark As Safe Events
                                </div>
                                <div
                                    className='text-muted'
                                    style={{ fontSize: '.75em' }}
                                >
                                    Based on Batch
                                </div>
                            </Col>
                        </Col>
                        <Col
                            md='5'
                            style={{
                                display: 'flex',
                                width: '60%',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <div>
                                {filterVisible && (
                                    <FilterButtonGroup
                                        onFilterChange={handleFilterChange}
                                        selectedFilter={selectedFilter}
                                    />
                                )}
                            </div>
                            <Nav variant='pills'>
                                <Nav.Item className='ms-auto'>
                                    <Nav.Link
                                        type='button'
                                        className='px-4 py-2'
                                        style={filterButtonStyle}
                                        onClick={toggleFilterVisibility}
                                    >
                                        <small
                                            style={{
                                                fontSize: '11px',
                                                lineHeight: '27px',
                                            }}
                                        >
                                            Filter &nbsp;
                                            {filterVisible ? <Close /> : <Filter />}
                                        </small>
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </Col>
                    </Row>
                }
            />
        </>
    );
}

export default MarkAsSafeTable;
