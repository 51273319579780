import React from 'react';
import { ReactComponent as Edit } from '../../asset/icons/Edit.svg';
import DeleteWithWarning from '../DeleteWithWarning';
import ModalWrapper from '../ModalWrapper';
import { ReactComponent as SortUp } from '../../asset/icons/SortUp.svg';
import { ReactComponent as SortDown } from '../../asset/icons/SortDown.svg';
import { ReactComponent as Sort } from '../../asset/icons/Sort.svg';

function TableTemplate(props) {
    const {
        name,
        irremovable,
        immutable,
        updateFormProps,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        endpoints,
        reFetch,
        formType,
        modalSize,
        showAlert,
    } = props;

    return (
        <div className='table-responsive'>
            <div
                className='table'
                {...getTableProps()}
                id='table-to-xls'
            >
                <div className='thead'>
                    {headerGroups.map((headerGroup) => (
                        <div
                            className='tr'
                            {...headerGroup.getHeaderGroupProps()}
                            style={{
                                border: '1px solid #EFEEFE',
                                background: '#EFEEFE',
                                color: '#467AFF',
                                fontSize: '14px',
                                fontWeight: '600',
                            }}
                        >
                            {headerGroup.headers.map((column) => (
                                <div
                                    className='th'
                                    {...column.getHeaderProps()}
                                >
                                    {column.render('Header')}

                                </div>
                            ))}

                            {!immutable && (
                                <div className='th text-center'>Edit</div>
                            )}
                            {!irremovable && (
                                <div className='th text-center'>Delete</div>
                            )}
                        </div>
                    ))}
                </div>

                <div
                    className='tbody pt-2'
                    {...getTableBodyProps()}
                >
                    {page.map((row) => {
                        prepareRow(row);
                        return (
                            <div
                                className='tr'
                                {...row.getRowProps()}
                                style={{
                                    border: '1px solid #EFEEFE',
                                    textAlign: 'center',
                                }}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <div
                                            className='td'
                                            {...cell.getCellProps()}
                                            style={{
                                                border: '1px solid #EFEEFE',
                                            }}
                                        >
                                            {cell.render('Cell')}
                                        </div>
                                    );
                                })}

                                {!immutable && (
                                    <div className='td text-center'>
                                        <ModalWrapper
                                            modalTitle={'Edit ' + name}
                                            modalAttrs={{
                                                size: modalSize.update,
                                            }}
                                            renderModalBody={(closeModal) => (
                                                <formType.update
                                                    onAfterSubmit={() => {
                                                        closeModal();
                                                        showAlert(
                                                            'success',
                                                            `The Record has been updated successfully`
                                                        );
                                                        reFetch();
                                                    }}
                                                    onCancel={closeModal}
                                                    endpoint={endpoints.update}
                                                    updateValues={{
                                                        ...row.original,
                                                    }}
                                                    {...updateFormProps}
                                                />
                                            )}
                                        >
                                            <Edit className='btnAnime' />
                                        </ModalWrapper>
                                    </div>
                                )}
                                {!irremovable && (
                                    <div className='td text-center'>
                                        <DeleteWithWarning
                                            title={name}
                                            configBody={{
                                                ...row.original,
                                            }}
                                            className='mx-auto'
                                            onAfterDelete={() => {
                                                showAlert(
                                                    'success',
                                                    `The Record has been removed successfully`
                                                );
                                                reFetch();
                                            }}
                                            endpoint={endpoints.delete}
                                        />
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default TableTemplate;
