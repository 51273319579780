import React, { useState } from "react";
import { useAxiosGet } from "../../hooks/axiosHooks";
import endpoints from "../../endpoints";
import viewProps from "../viewProps";
import AlumniPersonalTagForm from "./AlumniPersonalTagForm";
import { useAuthenticationState } from "../../context/Auth.context";
import DownloadDataAsExcel from "../../components/DownloadDataAsExcel";
import PaginatedTable from "../../components/table/PaginatedTable";
import GlobalSearch from "../../components/GlobalSearch";

function AlumniPersonalTag() {
    const { user } = useAuthenticationState();
    const [page, setPage] = useState(1);
    const [triggerApiCall, setTriggerApiCall] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    let url = `${endpoints.alumniPersonalTag.get}alumni?page=${page}&limit=10`;
    let downloadUrl = `${endpoints.alumniPersonalTag.downloadExcel}?tagRelatedTo=alumni`

    if (searchTerm) {
        url = `${endpoints.alumniPersonalTag.get}alumni?searchTerm=${searchTerm}&page=${page}&limit=10`;
        downloadUrl += `&searchTerm=${searchTerm}`
    }


    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };

    const fetchUtils = useAxiosGet(url, {
        preventCall: !triggerApiCall || searchTerm,
    });
    // Create a new object that includes the filtered data
    const fetchUtilsWithFilteredData = {
        ...fetchUtils,
    };
    // Log the filtered data for debugging

    const cellModifier = {
        alumni_personal_tags: ({ value }) => {
            // Split the comma-separated string into an array
            const uniqueTags = value ? value?.split(",") : [];

            return (
                <div
                    style={{
                        maxWidth: "500px",
                        overflow: "auto",
                        paddingBottom: "5px",
                    }}
                >
                    {uniqueTags?.map((tagName) => (
                        <div
                            key={tagName.trim()} // Use trimmed value as key to avoid extra spaces
                            className="p-2 me-2 d-inline-block small"
                            style={{
                                background: "rgba(95, 179, 246, 0.1)",
                                borderRadius: "10px",
                                border: "1px solid #e6eced",
                                boxShadow: "3px 3px 2px rgba(0, 0, 0, 0.4)",
                            }}
                        >
                            {tagName?.trim()} {/* Display the trimmed tag */}
                        </div>
                    ))}
                </div>
            );
        },
    };
    const handleNext = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handlePrevious = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };
    return (
        <>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                endpoints={endpoints.alumniPersonalTag}
                Form={AlumniPersonalTagForm}
                {...viewProps.AlumniPersonalTag}
                cellModifier={cellModifier}
                addBtnLabel="Add Mapping"
                formSize="md"
                irremovable
                showSearch={false}
                immutable={[
                    "STUDENTCOUNCIL",
                    "CLASSREPRESENTATIVE",
                    "CLASSREP + STUDENT COUNCIL",
                ].includes(user.Role)}
                insertable={
                    ![
                        "STUDENTCOUNCIL",
                        "CLASSREPRESENTATIVE",
                        "CLASSREP + STUDENT COUNCIL",
                    ].includes(user.Role)
                }
                {...fetchUtilsWithFilteredData}
                response={fetchUtils.response.responseData}
                downloadable={false}
                headerExtras={
                    <DownloadDataAsExcel
                        filename={"Alumni Personal Tag Mapping Details"}
                        apiEndpoint={downloadUrl}
                    />
                }
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm}
                        setSearchTerm={handleSearch}
                        placeholder="Search by alumni name"
                    />
                }
            />
        </>
    );
}

export default AlumniPersonalTag;
