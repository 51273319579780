import React, { useState } from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import PersonalTagForm from './PersonalTagForm';
import DeleteWithWarningTags from '../../components/DeleteWithWarningTags';
import withAlert from '../../components/withAlert';
import PaginatedTable from '../../components/table/PaginatedTable';
import GlobalSearch from '../../components/GlobalSearch';
import DownloadDataAsExcel from '../../components/DownloadDataAsExcel';

function PersonalTags({ showAlert }) {
    const [page, setPage] = useState(1);
    const [triggerApiCall, setTriggerApiCall] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");

    let url = `${endpoints.personalTags.get}?page=${page}&limit=10`;
    let downloadUrl = endpoints.personalTags.downloadExcel;

    if (searchTerm) {
        url = `${endpoints.personalTags.get}?searchTerm=${searchTerm}&page=${page}&limit=10`;
        downloadUrl += `?searchTerm=${searchTerm}`
    }

    const fetchUtils = useAxiosGet(url, {
        preventCall: !triggerApiCall || searchTerm,
    });

    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };

    const handleNext = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handlePrevious = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const cellModifier = {
        Delete: ({ row }) => (
            <div className='td text-center'>
                <DeleteWithWarningTags
                    title={'Personal Tags'}
                    configBody={{
                        ...row.original,
                    }}
                    className='mx-auto'
                    onAfterDelete={() => {
                        fetchUtils.reFetch();
                        showAlert(
                            'success',
                            'The tag has been removed successfully'
                        );
                    }}
                    endpoint={endpoints.personalTags.delete}
                />
            </div>
        ),
    };
    return (
        <>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                endpoints={endpoints.personalTags}
                {...viewProps.PersonalTags}
                relevants={[
                    ...(viewProps.PersonalTags.relevants || []),
                    ...['Delete'],
                ]}
                columnHeads={[
                    ...(viewProps.PersonalTags.columnHeads || []),
                    ...['Delete'],
                ]}
                Form={PersonalTagForm}
                {...fetchUtils}
                response={fetchUtils.response.responseData}
                irremovable
                cellModifier={cellModifier}
                showSearch={false}
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm}
                        setSearchTerm={handleSearch}
                        placeholder="Search Tags by name"
                    />
                }
                downloadable={false}
                headerExtras={
                    <DownloadDataAsExcel
                        filename={"Personal tag Details"}
                        apiEndpoint={downloadUrl}
                    />
                }
            />
        </>
    );
}

export default withAlert(PersonalTags);
