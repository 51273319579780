import React, { useState } from "react";
// import CRUDTable from "../../components/table/CRUDTable";
import { useAxiosGet } from "../../hooks/axiosHooks";
import endpoints from "../../endpoints";
import viewProps from "../viewProps";
import CityChapterHeadForm from "./CityChapterHeadForm";
import PaginatedTable from "../../components/table/PaginatedTable";
import GlobalSearch from "../../components/GlobalSearch";
import DownloadDataAsExcel from "../../components/DownloadDataAsExcel";
import { useLocation } from "react-router-dom";

function CityChapterHead() {

    const cellModifier = {
        CCH_Elected_Date:({value}) => new Date(value).toDateString(),
        DCCH_Elected_Date:({value}) => new Date(value).toDateString(),
    };
    const { state } = useLocation();
    const [searchTerm, setSearchTerm] = useState(state || "");


    const [page, setPage] = useState(1);
    const [triggerApiCall, setTriggerApiCall] = useState(true);

    // const url = !searchTerm
    //     ? `${endpoints.cityChapterHead.list}?page=${page}&limit=10`
    //     : `${endpoints.cityChapterHead.list}?searchTerm=${searchTerm}&page=${page}&limit=10`;

    let url = `${endpoints.cityChapterHead.list}?page=${page}&limit=10`;
    let downloadUrl = endpoints.cityChapterHead.downloadExcel;
    
    if (searchTerm) {
        url += `&searchTerm=${searchTerm}`;
        downloadUrl += `?searchTerm=${searchTerm}`; 
    }

    const fetchUtils = useAxiosGet(url, {
        preventCall: !triggerApiCall || searchTerm,
    });

    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };

    const handleNext = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handlePrevious = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };
    return (
        <>
         <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                endpoints={endpoints.cityChapterHead}
                Form={CityChapterHeadForm}
                {...viewProps.CityChapterHead}
                relevants={[...(viewProps.CityChapterHead.relevants || [])]}
                columnHeads={[
                    ...(viewProps.CityChapterHead.columnHeads || []),

                ]}
                {...fetchUtils}
                response={fetchUtils.response.responseData}
                name={`City Chapter Head`}
                cellModifier={cellModifier}
                addBtnLabel={`Add City Chapter Head`}
                showSearch={false}
                headerExtras={
                    <>
                        <DownloadDataAsExcel
                            filename={"City Chapter Head Details"}
                            apiEndpoint={downloadUrl}
                        />
                    </>
                }
                downloadable={false}
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm || state}
                        setSearchTerm={handleSearch}
                        placeholder="Search..."
                    />
                }
            />
            {/* <CRUDTable
                endpoints={endpoints.cityChapterHead}
                {...viewProps.CityChapterHead}
                Form = {CityChapterHeadForm}
                cellModifier={cellModifier}
                {...fetchUtils}
            /> */}
        </>
    );
}

export default CityChapterHead;
