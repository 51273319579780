import React from 'react';
import Avatar from '../../asset/img/Avatar.png';
import './Blood.css';
const bloodGroups = ['A+', 'B-', 'O+', 'AB+', 'A-'];
const types = ['student', 'faculty', 'alumni'];

const BloodDonor = ({ bloodDonor }) => {

    return (
        <div style={{ borderRadius: '12px', overflow: 'hidden' }}>

            <div className='scrollable-container'>
                <table
                    className='table'
                    style={{
                        width: '100%',
                        borderCollapse: 'collapse',
                    }}
                >
                    <thead>
                        <tr>
                            <th
                                style={{
                                    padding: '10px',
                                    backgroundColor: '#E0E7FF',
                                    color: '#0000FF',
                                    fontSize: '16px',
                                    textAlign: 'left',
                                }}
                            >
                                User Name
                            </th>
                            <th
                                style={{
                                    padding: '10px',
                                    backgroundColor: '#E0E7FF',
                                    color: '#0000FF',
                                    fontSize: '16px',
                                    textAlign: 'center',
                                }}
                            >
                                Blood Group
                            </th>
                        </tr>
                    </thead>
                    <tbody>

                        {bloodDonor?.map((person, index) => (
                            <tr key={index}>
                                <td
                                    style={{
                                        borderBottom: '1px solid #eee',
                                        padding: '10px',
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <img
                                        src={person.ALUMNI_IMAGE_URL || Avatar}
                                        alt='Avatar'
                                        className='rounded-circle shadow-sm'
                                        style={{
                                            border: '3px solid white',
                                            width: '40px',
                                            height: '40px',
                                            marginRight: '10px',
                                        }}
                                    />
                                    <div
                                        style={{
                                            fontSize: '14px',
                                        }}
                                    >
                                        <strong>{person.ALUMNI_Name}</strong>
                                        <br />
                                        <div title='Batches' style={{ fontSize: 12, fontWeight: 500, color: 'grey' }}>{person.BATCH_NAME}</div>
                                    </div>
                                </td>
                                <td
                                    style={{
                                        textAlign: 'center',
                                        borderBottom: '1px solid #eee',
                                        padding: '10px',
                                        fontSize: '14px',
                                        color: '#000',
                                        width: '50%',
                                    }}
                                >
                                    {person.bloodGroups}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default BloodDonor;
