import React, {useState} from 'react';
import CRUDTable from '../../components/table/CRUDTableForSc';
import { useAuthenticationState } from '../../context/Auth.context';
import { useAxiosGet } from '../../hooks/axiosHooks';
function BatchRepCrud(props)
{

    const {
        responseApi,
        viewProps,
        endpoints,
        cellModifier,
        setCount,
        formSize = 'md',
        irremovable = true, 
        immutable = true, 
        insertable = false, 
        showSearch = false, 
        downloadable = false, 
        headerExtras = (
          <></>
        ), 
      } = props;
     
    const { user } = useAuthenticationState();
    const [page, setPage] = useState(1);
    const getApi = `${responseApi}?page=${page}&limit=10`;
    const fetchUtils = useAxiosGet(getApi);
    setCount(fetchUtils.response?.pagination?.totalRecords);

    const handleNext = (page) => {
        setPage(page);
    };

    const handlePrevious = (page) => {
        setPage(page);
    };

    if (fetchUtils.isLoading) {
        return <div>Loading...</div>;
    }

    return (
<>
        <CRUDTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                endpoints={endpoints}
                {...viewProps}
                cellModifier={cellModifier}
                // addBtnLabel='Add Event'
                formSize={formSize}
                {...fetchUtils}
                response={fetchUtils.response.responseData}
                irremovable={irremovable}
                immutable={immutable}
                insertable={insertable}
                showSearch={showSearch}
                downloadable={downloadable}
                headerExtras={headerExtras}
            />
        </>
    );
}

export default BatchRepCrud;