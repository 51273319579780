import React from 'react';
import { Tab } from 'react-bootstrap';
import MarkAsSafeTable from './MarkAsSafeTable';

function MarkAsSafe() {


    return (
        <Tab.Container defaultActiveKey='SafeEvent'>


            <Tab.Content className='rounded-smoother mt-3 card-shadow'>
                <Tab.Pane eventKey='SafeEvent' title='SafeEvent'>
                    <MarkAsSafeTable />
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
    );
}

export default MarkAsSafe;
