import React, { useState } from 'react';
// import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import BatchHeadForm from './BatchHeadForm';
import { useAuthenticationState } from '../../context/Auth.context';
import PaginatedTable from '../../components/table/PaginatedTable';
import DownloadDataAsExcel from '../../components/DownloadDataAsExcel';
import GlobalSearch from '../../components/GlobalSearch';
import { useLocation } from 'react-router-dom';

function BatchHeads() {
    // const fetchUtils = useAxiosGet(endpoints.batchHeads.get);

    const { state } = useLocation();
    const [searchTerm, setSearchTerm] = useState(state || "");


    const [page, setPage] = useState(1);
    const [triggerApiCall, setTriggerApiCall] = useState(true);

    // const url = !searchTerm
    //     ? `${endpoints.batchHeads.get}?page=${page}&limit=10`
    //     : `${endpoints.batchHeads.get}?searchTerm=${searchTerm}&page=${page}&limit=10`;

    let url = `${endpoints.batchHeads.get}?page=${page}&limit=10`;
    let downloadUrl = endpoints.batchHeads.downloadExcel;
    
    if (searchTerm) {
        url += `&searchTerm=${searchTerm}`;
        downloadUrl += `?searchTerm=${searchTerm}`; 
    }
    const fetchUtils = useAxiosGet(url, {
        preventCall: !triggerApiCall || searchTerm,
    });

    const handleSearch = (value) => {
        setSearchTerm(value?.trim());
    };

    const handleNext = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const handlePrevious = (page) => {
        setPage(page);
        setTriggerApiCall(true);
    };

    const { user } = useAuthenticationState();
    return (
        <>
            <PaginatedTable
                handleNext={handleNext}
                handlePrevious={handlePrevious}
                pagination={fetchUtils.response?.pagination}
                endpoints={endpoints.batchHeads}
                Form={BatchHeadForm}
                {...viewProps.BatchHeads}
                relevants={[...(viewProps.BatchHeads.relevants || [])]}
                columnHeads={[
                    ...(viewProps.BatchHeads.columnHeads || []),

                ]}
                {...fetchUtils}
                response={fetchUtils.response.responseData}
                name={`Batch Representatives`}
                // cellModifier={cellModifier}
                addBtnLabel={`Add Batch Representative`}
                showSearch={false}
                headerExtras={
                    <>

                        <DownloadDataAsExcel
                            filename={"Batch Representative Details"}
                            apiEndpoint={downloadUrl}
                        />
                    </>
                }
                irremovable={user.Role !== 'ADMIN' && user.Role !== 'GCM'}
                immutable={user.Role !== 'ADMIN' && user.Role !== 'GCM'}
                insertable={user.Role === 'ADMIN' || user.Role === 'GCM'}
                // errorObjects={errorObjects}
                downloadable={false}
                customSearch={
                    <GlobalSearch
                        searchTerm={searchTerm || state}
                        setSearchTerm={handleSearch}
                        placeholder="Search Alumni by name"
                    />
                }
            />
            {/* <CRUDTable
                endpoints={endpoints.batchHeads}//
                Form={BatchHeadForm}//
                {...viewProps.BatchHeads}
                {...fetchUtils}
                irremovable={user.Role !== 'ADMIN' && user.Role !== 'GCM'}//
                immutable={user.Role !== 'ADMIN' && user.Role !== 'GCM'}//
                insertable={user.Role === 'ADMIN' || user.Role === 'GCM'}//
            
            /> */}
        </>
    );
}

export default BatchHeads;
