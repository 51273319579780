import React, { useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { ReactComponent as Help } from '../../asset/icons/Help.svg';
import BasicTable from './BasicTable';
import { useAuthenticationState } from '../../context/Auth.context';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import { useAxiosGet } from '../../hooks/axiosHooks';
import Avatar from '../../asset/img/Avatar.png';
import RequestModal from './RequestModal';

function HelpJobRequest() {
    const { user } = useAuthenticationState();

    // Modal state and functions
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [tableRowContent, setTableRowContent] = useState({});
    const [selectedEventKey, setSelectedEventKey] = useState('Event'); // Track the selected event key

    const handleShowModal = (content, row) => {
        setModalContent(content);
        setShowModal(true);
        setTableRowContent(row);
    };

    const handleCloseModal = () => setShowModal(false);

    const getApi = endpoints.helpRequests.scHelpRequestForApproval;

    const fetchUtils = useAxiosGet(getApi);

    const { response } = fetchUtils;

    const cellModifierEvent = {
        CREATEDDATE: ({ value }) => new Date(value).toDateString(),
        Alumni_HelpRequest_ExpiryDate: ({ value }) =>
            value ? new Date(value).toDateString() : value,
        View: ({ row }) => (
            <span
                style={{
                    textDecoration: 'underline',
                    color: '#0D8D1A',
                    cursor: 'pointer',
                }}
                onClick={() =>
                    handleShowModal(
                        row.original.Alumni_HelpRequest_ID,
                        row.original
                    )
                }
            >
                View Request
            </span>
        ),
        Alumni_HelpRequest_AlumniNAME: ({ row }) => {
            if (row.original) {
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '42px',
                                height: '42px',
                            }}
                        >
                            <img
                                src={row.original.ALUMNI_IMAGE_URL || Avatar}
                                alt='Avatar'
                                className='rounded-circle shadow-sm'
                                style={{
                                    border: '3px solid white',
                                    width: '100%',
                                }}
                            />
                        </div>

                        <div
                            style={{
                                paddingLeft: '2%',
                                fontSize: '14px',
                                color: '#4C4C4C',
                                textAlign: 'start',
                            }}
                        >
                            <span>
                                {row.original.Alumni_HelpRequest_AlumniNAME}
                            </span>
                            <br />
                            <span>{row.original.BATCH_NAME}</span>
                        </div>
                    </div>
                );
            } else {
                return row.original;
            }
        },
        Alumni_HelpRequest_Type: ({ value }) => {
            const types = {
                1: 'MEDICAL RELATED',
                2: 'CALAMITY RELATED',
                3: 'QUERIES',
                4: 'PERSONAL',
                5: 'LEGAL ADVICE',
                6: 'EMPLOYMENT RELATED',
            };
            return types[Number(value)];
        },
        Alumni_HelpRequest_PRIORITY: ({ value }) => {
            const types = {
                1: 'URGENT',
                2: 'IMMEDIATE',
                3: 'NOT URGENT',
            };
            return types[value];
        },
    };

    const getApiAnnounce =
        endpoints.alumniLookingForJob.scJobRequestsForApproval;
    const fetchUtilsAnnounce = useAxiosGet(getApiAnnounce);

    const cellModifierAnnounce = {
        DateCreated: ({ value }) => new Date(value).toDateString(),
        View: ({ row }) => (
            <span
                style={{
                    textDecoration: 'underline',
                    color: '#0D8D1A',
                    cursor: 'pointer',
                }}
                onClick={() =>
                    handleShowModal(row.original.ALUMNI_JOB_CODE, row.original)
                }
            >
                View Request
            </span>
        ),
        ALUMNI_Name: ({ row }) => {
            if (row.original) {
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '42px',
                                height: '42px',
                            }}
                        >
                            <img
                                src={row.original.ALUMNI_IMAGE_URL || Avatar}
                                alt='Avatar'
                                className='rounded-circle shadow-sm'
                                style={{
                                    border: '3px solid white',
                                    width: '100%',
                                }}
                            />
                        </div>

                        <div
                            style={{
                                paddingLeft: '2%',
                                fontSize: '14px',
                                color: '#4C4C4C',
                                textAlign: 'start',
                            }}
                        >
                            <span>{row.original.ALUMNI_Name}</span>
                            <br />
                            <span>{row.original.BATCH_NAME}</span>
                        </div>
                    </div>
                );
            } else {
                return row.original;
            }
        },
    };

    const approveConfigBody =
        selectedEventKey === 'Event'
            ? {
                Alumni_HelpRequest_RESPONSE_COMMENT: 'Approved',
                Alumni_HelpRequest_RESPONSE_ID: '1',
                Alumni_HelpRequest_ID: modalContent,
                Alumni_HelpRequest_AlumniID: user.ALUMNI_BATCH_ADM_CODE,
                Alumni_HelpRequest_TITLE:
                    tableRowContent.Alumni_HelpRequest_TITLE,
                NOTIFICATION_CONTENT: 'Help request has been approved',
            }
            : {
                Job_Requirement_Status_Approve: '1',
                ALUMNI_JOB_CODE: modalContent,
                CREATEDBY: user.ALUMNI_BATCH_ADM_CODE,
                NOTIFICATION_CONTENT: 'Job request has been approved',
                //   JobRequirementDetails: modalContent.JobRequirementDetails,
                //   DomainLookout: modalContent.DomainLookout,
            };

    const rejectConfigBody =
        selectedEventKey === 'Event'
            ? {
                Alumni_HelpRequest_RESPONSE_COMMENT: 'Rejected',
                Alumni_HelpRequest_RESPONSE_ID: '2',
                Alumni_HelpRequest_ID: modalContent,
                Alumni_HelpRequest_AlumniID: user.ALUMNI_BATCH_ADM_CODE,
                Alumni_HelpRequest_TITLE:
                    tableRowContent.Alumni_HelpRequest_TITLE,
                NOTIFICATION_CONTENT: 'Help request has been rejected',
            }
            : {
                Job_Requirement_Status_Approve: '2',
                ALUMNI_JOB_CODE: modalContent,
                CREATEDBY: user.ALUMNI_BATCH_ADM_CODE,
                NOTIFICATION_CONTENT: 'Job request has been rejected',
                //   JobRequirementDetails: modalContent.JobRequirementDetails,
                //   DomainLookout: modalContent.DomainLookout,
            };

    return (
        <>
            <Tab.Container
                defaultActiveKey='Event'
                onSelect={(k) => setSelectedEventKey(k)} // Set the selected event key
            >
                <Row className='px-2'>
                    <Col className='d-flex'>
                        <Help />
                        <Col className='px-2'>
                            <Tab.Content>
                                <Tab.Pane eventKey='Event' title='Event'>
                                    <div className='small h6 text-dark'>
                                        Pending Help Requests for Approval
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane
                                    eventKey='Announcements'
                                    title='Announcements'
                                >
                                    <div className='small h6 text-dark'>
                                        Pending Job Requests for Approval
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>

                            <div
                                className='text-muted'
                                style={{ fontSize: '.75em' }}
                            >
                                Based on Batch
                            </div>
                        </Col>
                    </Col>
                    <Col md='5'>
                        <Nav variant='pills'>
                            <Nav.Item className='ms-auto'>
                                <Nav.Link
                                    type='button'
                                    className='p-3 py-1'
                                    eventKey='Event'
                                >
                                    <small
                                        style={{
                                            fontSize: '11px',
                                            lineHeight: '27px',
                                        }}
                                    >
                                        Help Request
                                    </small>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link
                                    type='button'
                                    className='p-3 py-1'
                                    eventKey='Announcements'
                                >
                                    <small
                                        style={{
                                            fontSize: '11px',
                                            lineHeight: '27px',
                                        }}
                                    >
                                        Job Request
                                    </small>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>

                <Tab.Content className='rounded-smoother mt-3 card-shadow'>
                    <Tab.Pane eventKey='Event' title='Event'>
                        <BasicTable
                            fetchUtils={fetchUtils}
                            cellModifier={cellModifierEvent}
                            name={viewProps.HelpDash}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey='Announcements' title='Announcements'>
                        <BasicTable
                            fetchUtils={fetchUtilsAnnounce}
                            cellModifier={cellModifierAnnounce}
                            name={viewProps.JobDash}
                        />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
            {showModal && selectedEventKey === 'Event' && (
                <RequestModal
                    reFetch={fetchUtils.reFetch}
                    isModalOpen={showModal}
                    setIsModalOpen={setShowModal}
                    endpoint={endpoints.helpRequests.respond}
                    approveConfigBody={approveConfigBody}
                    rejectConfigBody={rejectConfigBody}
                    name='Pending Help Requests'
                    tableRowContent={tableRowContent}
                />
            )}
            {showModal && selectedEventKey === 'Announcements' && (
                <RequestModal
                    reFetch={fetchUtilsAnnounce.reFetch}
                    isModalOpen={showModal}
                    setIsModalOpen={setShowModal}
                    endpoint={endpoints.alumniLookingForJob.respond}
                    approveConfigBody={approveConfigBody}
                    rejectConfigBody={rejectConfigBody}
                    name='Pending Job Requests'
                    tableRowContent={tableRowContent}
                    isJobRequest
                />
            )}
        </>
    );
}

export default HelpJobRequest;
