import React from 'react';
import CRUDTable from '../../components/table/CRUDTable';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import viewProps from '../viewProps';
import InsuranceProviderForm from './BenefitProviderForm';
import { Tab, Tabs } from 'react-bootstrap';

function BenefitProviders() {
    const { response, ...fetchUtils } = useAxiosGet(
        endpoints.benefitProviders.get
    );
    const cellModifier = {
        Alumni_InsuranceProvider_Status: ({ value }) => {
            if (Number(value) === 2) {
                return 'Inactive';
            }
            if (Number(value) === 1) {
                return 'Active';
            }
            return '';
        },
    };

    const life = response.filter(
        (benefit) => Number(benefit.Alumni_InsuranceProvider_Classification) === 1
    );
    const health = response.filter(
        (benefit) => Number(benefit.Alumni_InsuranceProvider_Classification) === 2
    );
    const card = response.filter(
        (benefit) => Number(benefit.Alumni_InsuranceProvider_Classification) === 3
    );
    const subscription = response.filter(
        (benefit) => Number(benefit.Alumni_InsuranceProvider_Classification) === 4
    );

    return (
        <div className='custom-tab'>
            <Tabs fill className='mb-4' mountOnEnter>
                <Tab eventKey='life' title='Life' >
                    <CRUDTable
                        endpoints={endpoints.benefitProviders}
                        {...viewProps.BenefitProviders}
                        cellModifier={cellModifier}
                        Form={InsuranceProviderForm}
                        addFormProps={{ classification: '1' }}
                        updateFormProps={{ classification: '1' }}
                        name='Life Insurance'
                        {...fetchUtils}
                        response={life}
                    />
                </Tab>
                <Tab eventKey='health' title='Health'>
                    <CRUDTable
                        endpoints={endpoints.benefitProviders}
                        {...viewProps.BenefitProviders}
                        cellModifier={cellModifier}
                        Form={InsuranceProviderForm}
                        addFormProps={{ classification: '2' }}
                        updateFormProps={{ classification: '2' }}
                        name='Health Insurance'
                        {...fetchUtils}
                        response={health}
                    />
                </Tab>
                {/* <Tab eventKey='card' title='Card'>
                    <CRUDTable
                        endpoints={endpoints.benefitProviders}
                        {...viewProps.BenefitProviders}
                        cellModifier={cellModifier}
                        Form={InsuranceProviderForm}
                        addFormProps={{ classification: '3' }}
                        updateFormProps={{ classification: '3' }}
                        name='Card Eligibility'
                        {...fetchUtils}
                        response={card}
                    />
                </Tab>
                <Tab eventKey='subscription' title='Subscription'>
                    <CRUDTable
                        endpoints={endpoints.benefitProviders}
                        {...viewProps.BenefitProviders}
                        cellModifier={cellModifier}
                        Form={InsuranceProviderForm}
                        addFormProps={{ classification: '4' }}
                        updateFormProps={{ classification: '4' }}
                        name='Subscriptions'
                        {...fetchUtils}
                        response={subscription}
                    />
                </Tab> */}
            </Tabs>
        </div>
    );
}

export default BenefitProviders;
