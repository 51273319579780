import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import FormikControl from '../../components/formikControl/FormikControl';
import withAlert from '../../components/withAlert';
import Loader from '../../components/Loader';
import FormSubmissionBtn from '../../components/FormSubmissionBtn';
import extractErrorFromRes from '../../helpers/extractErrorFromRes';
import { Col, Row } from 'react-bootstrap';
import { useAxiosGet } from '../../hooks/axiosHooks';
import endpoints from '../../endpoints';
import { checkIsoAndReturnYMDFormat } from '../../helpers/dateFunctions';
import moment from 'moment';
import { useLabelProvider } from '../../context/LabelProvider';
import { useAuthenticationState } from '../../context/Auth.context';

function EventForm({
    endpoint,
    onCancel,
    onAfterSubmit,
    showAlert,
    updateValues,
}) {
    const { setLabels } = useLabelProvider();
    const { user } = useAuthenticationState();

    useEffect(() => {
        setLabels('EventCalendar');
    }, []);

    const { response: batches, loading } = useAxiosGet(endpoints.batch.batchID);

    const submitHandler = (values, { setSubmitting }) => {
        const body = { ...values };

        const startAt = moment(body?.Event_CALENDAR_SCHEDULE_STARTDATE);
        const endAt = moment(body?.Event_CALENDAR_SCHEDULE_ENDDATE);

        startAt.set({
            hour: moment(body?.Event_CALENDAR_SCHEDULE_STARTTIME).get('hour'),
            minute: moment(body?.Event_CALENDAR_SCHEDULE_STARTTIME).get(
                'minute'
            ),
        });
        endAt.set({
            hour: moment(body?.Event_CALENDAR_SCHEDULE_ENDTIME)?.get('hour'),
            minute: moment(body?.Event_CALENDAR_SCHEDULE_ENDTIME)?.get('minute'),
        });

        const duration = endAt.diff(startAt, 'minutes');

        body.Event_CALENDAR_SCHEDULE_STARTTIME = startAt?._d.toISOString();
        body.Event_CALENDAR_SCHEDULE_ENDTIME = endAt?._d.toISOString();

        if (duration < 10) {
            showAlert('error', 'Given date/time is improper');
            setSubmitting(false);
            return;
        }
        body.Event_CALENDAR_SCHEDULE_DURATION = duration;

        if (body.BATCH_CODE && !body?.COURSE_ID) {
            body.COURSE_ID = batches.find(
                (batch) => batch.BATCH_CODE === body.BATCH_CODE
            )?.COURSE_ID;
        }

        axios
            .post(endpoint, body)
            .then(() => {
                onAfterSubmit();
            })
            .catch((err) => {
                showAlert(
                    'error',
                    err.response.data?.statusText || 'Something went wrong'
                );
            })
            .finally((res) => setSubmitting(false));
    };

    if (updateValues) {
        updateValues.Event_CALENDAR_SCHEDULE_STARTDATE =
            checkIsoAndReturnYMDFormat(
                updateValues.Event_CALENDAR_SCHEDULE_STARTDATE
            );
        updateValues.Event_CALENDAR_SCHEDULE_ENDDATE =
            checkIsoAndReturnYMDFormat(
                updateValues.Event_CALENDAR_SCHEDULE_ENDDATE
            );
        updateValues.Event_CALENDAR_EVENT_STATUS = String(
            updateValues.Event_CALENDAR_EVENT_STATUS
        );
    }
    const initialFormValues = updateValues || {
        Event_CALENDAR_TYPE: '',
        Event_Event_Type: '',
        Event_Location: '',
        Event_CALENDAR_SCHEDULE_STARTDATE: '',
        Event_CALENDAR_SCHEDULE_STARTTIME: '',
        Event_CALENDAR_SCHEDULE_ENDDATE: '',
        Event_CALENDAR_SCHEDULE_ENDTIME: '',
        Event_CALENDAR_EVENT_NAME: '',
        Event_CALENDAR_EVENT_DESC: '',
        Event_CALENDAR_SCHEDULE_DURATION: '',
        Event_CALENDAR_EVENT_STATUS: '',
        BATCH_CODE: user.DELEGATE_BATCH_CODE || '',
        COURSE_ID: '',
        Event_CALENDAR_BROADCAST_TYPE: '',
    };
    const preSelectedOption = {};

    if (updateValues) {
        preSelectedOption.batch = batches.find(
            (batch) =>
                Number(batch.BATCH_CODE) ===
                Number(initialFormValues.BATCH_CODE)
        );
    }
    const uniqueCourses = [...new Map(batches.map(item => [item.COURSE_ID, item])).values()];
    console.log(uniqueCourses);
    
    const eventType = {
        'Ground Event': 1,
        Webinar: 2,
        Seminar: 3,
        'Virtual Event': 4,
        'Club Event': 5,
        'Org Event': 6,
        Meetup: 7,
        Charity: 8,
        'Alumni Event': 9,
        // IIMK: 10,
    };
    if (loading) {
        return <Loader />;
    }

    return (
        <Formik
            initialValues={initialFormValues}
            validationSchema={Yup.object({
                Event_CALENDAR_TYPE: Yup.string().required(),
                Event_Event_Type: Yup.string().required(),
                Event_Location: Yup.string().required(),
                Event_CALENDAR_SCHEDULE_STARTDATE: Yup.date()
                    .min(
                        new Date().toDateString(),
                        'Start Date must be in future'
                    )
                    .required(),
                Event_CALENDAR_SCHEDULE_STARTTIME: Yup.string()
                    .test(
                        'Event_CALENDAR_SCHEDULE_STARTTIME',
                        'The start time should be later from now',
                        function (value) {
                            const { Event_CALENDAR_SCHEDULE_STARTDATE } =
                                this.parent;

                            if (
                                moment(value).format('ddd MMM DD YYYY') ===
                                moment(
                                    Event_CALENDAR_SCHEDULE_STARTDATE
                                ).format('ddd MMM DD YYYY')
                            ) {
                                const isEventStartTimeValid =
                                    new Date().setSeconds(0, 0);

                                return moment(value).isSameOrAfter(
                                    moment(isEventStartTimeValid)
                                );
                            } else {
                                return true;
                            }
                        }
                    )
                    .required(),
                Event_CALENDAR_SCHEDULE_ENDDATE: Yup.date()
                    .min(
                        Yup.ref('Event_CALENDAR_SCHEDULE_STARTDATE'),
                        'End Date must be later than Start Date'
                    )
                    .required(),
                Event_CALENDAR_SCHEDULE_ENDTIME: Yup.string()
                    .test(
                        'Event_CALENDAR_SCHEDULE_ENDTIME',
                        'The end time should be greater than start time',
                        function (endTime) {
                            const {
                                Event_CALENDAR_SCHEDULE_ENDDATE,
                                Event_CALENDAR_SCHEDULE_STARTTIME,
                                Event_CALENDAR_SCHEDULE_STARTDATE,
                            } = this.parent;

                            const endDate = moment(
                                Event_CALENDAR_SCHEDULE_ENDDATE
                            ).format('ddd MMM DD YYYY');

                            const startDate = moment(
                                Event_CALENDAR_SCHEDULE_STARTDATE
                            ).format('ddd MMM DD YYYY');

                            if (endDate === startDate) {
                                const startTime = new Date(
                                    Event_CALENDAR_SCHEDULE_STARTTIME
                                ).setSeconds(0, 0);

                                return moment(endTime).isSameOrAfter(
                                    moment(startTime).add(1, 'minutes')
                                );
                            } else {
                                return true;
                            }
                        }
                    )
                    .required(),
                Event_CALENDAR_EVENT_NAME: Yup.string().required(),
                Event_CALENDAR_EVENT_DESC: Yup.string().required(),
                Event_CALENDAR_EVENT_STATUS: Yup.string().required(),
                BATCH_CODE: Yup.string().when('Event_CALENDAR_BROADCAST_TYPE', {
                    is: '2',
                    then: () => Yup.string().required('Required'),
                    otherwise: () => Yup.string().nullable(),
                }),
                COURSE_ID: user.Role !== 'DELEGATE'?Yup.string().when('Event_CALENDAR_BROADCAST_TYPE', {
                    is: '3',
                    then: () => Yup.string().required('Required'),
                    otherwise: () => Yup.string().nullable(),
                }):'',
                Event_CALENDAR_BROADCAST_TYPE: Yup.string().required(),
            })}
            onSubmit={submitHandler}
        >


            {({ isSubmitting, values, setFieldValue, errors }) => (
                <Form>
                    <Row>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                required
                                name='Event_CALENDAR_EVENT_NAME'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='select'
                                required
                                name='Event_CALENDAR_TYPE'
                            >
                                <option value=''>Select Calendar Type</option>
                                <option value='1'>Institution Initiated</option>
                                {/* <option value='2'>IIM ALUMNI Initiated</option> */}
                                <option value='3'>Individual Initiated</option>
                            </FormikControl>
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='select'
                                required
                                name='Event_Event_Type'
                            >
                                <option>Select Event Type</option>
                                {Object.keys(eventType).map((type) => (
                                    <option key={type} value={eventType[type]}>
                                        {type}
                                    </option>
                                ))}
                            </FormikControl>
                        </Col>
                        {/* {!user.DELEGATE_BATCH_CODE && (
                            <Col sm='6'>
                                <FormikControl
                                    control='select-advanced'
                                    required
                                    options={batches}
                                    defaultValue={preSelectedOption.batch}
                                    optionValue='BATCH_CODE'
                                    optionLabel='BATCH_NAME'
                                    label='BATCH'
                                    name='BATCH_CODE'
                                    onChange={({ BATCH_CODE, COURSE_ID }) => {
                                        setFieldValue(
                                            'BATCH_CODE',
                                            BATCH_CODE,
                                            true
                                        );
                                        setFieldValue(
                                            'COURSE_ID',
                                            COURSE_ID,
                                            true
                                        );
                                    }}
                                    getOptionLabel={(label) =>
                                        label.BATCH_NAME +
                                        ' ' +
                                        label.COURSE_NAME
                                    }
                                />
                            </Col>
                        )} */}
                        {/* <Col sm='6'>
                            <FormikControl
                                control='input'
                                required
                                name='COURSE_ID'

                            />
                        </Col> */}
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                required
                                name='Event_Location'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='check'
                                type='radio'
                                required
                                options={[
                                    { key: 'All', value: '1' },
                                    { key: 'Batch', value: '2' },
                                    { key: 'Course', value: '3' },
                                ]}
                                name='Event_CALENDAR_BROADCAST_TYPE'
                                onChange={(e) => {
                                    setFieldValue(
                                        'Event_CALENDAR_BROADCAST_TYPE',
                                        e.target.value
                                    );
                                    setFieldValue('BATCH_Array', []);
                                }}
                            />
                        </Col>



                        {Number(values.Event_CALENDAR_BROADCAST_TYPE) ===
                            2 &&
                            user.Role !== 'DELEGATE' && (
                                <Col sm='6'>
                                    <FormikControl
                                        control='select-advanced'
                                        required
                                        options={batches}
                                        defaultValue={preSelectedOption.batch}
                                        optionValue='BATCH_CODE'
                                        optionLabel='BATCH_NAME'
                                        label='BATCH'
                                        name='BATCH_CODE'
                                        onChange={({ BATCH_CODE, COURSE_ID }) => {
                                            setFieldValue(
                                                'BATCH_CODE',
                                                BATCH_CODE,
                                                true
                                            );
                                            setFieldValue(
                                                'COURSE_ID',
                                                COURSE_ID,
                                                true
                                            );
                                        }}
                                        getOptionLabel={(label) =>
                                            label.BATCH_NAME +
                                            ' ' +
                                            label.COURSE_NAME
                                        }
                                    />
                                </Col>
                            )}
                        {Number(values.Event_CALENDAR_BROADCAST_TYPE) === 3 && user.Role !== 'DELEGATE' && (
                            <Col sm='6'>
                                <FormikControl
                                    control='select-advanced'
                                    required
                                    options={uniqueCourses}
                                    defaultValue={preSelectedOption.batch}
                                    optionValue='COURSE_ID'
                                    optionLabel='COURSE_NAME'
                                    label='COURSE'
                                    name='COURSE_ID'
                                    onChange={(selectedCourse) => {
                                        // Assuming selectedCourse is an object from uniqueCourses
                                        const { BATCH_CODE, COURSE_ID } = selectedCourse;

                                        // Set values in Formik
                                        setFieldValue('BATCH_CODE', BATCH_CODE, true);
                                        setFieldValue('COURSE_ID', COURSE_ID, true);
                                    }}
                                    getOptionLabel={(label) => label.COURSE_NAME}
                                />
                            </Col>
                        )}


                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='date'
                                required
                                name='Event_CALENDAR_SCHEDULE_STARTDATE'
                                onChange={(e) => {
                                    //form update scenario
                                    setFieldValue(
                                        'Event_CALENDAR_SCHEDULE_STARTDATE',
                                        e.target.value
                                    );
                                    setFieldValue(
                                        'Event_CALENDAR_SCHEDULE_STARTTIME',
                                        ''
                                    );
                                }}
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='time'
                                required
                                disabled={
                                    !values.Event_CALENDAR_SCHEDULE_STARTDATE
                                }
                                name='Event_CALENDAR_SCHEDULE_STARTTIME'
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='input'
                                type='date'
                                required
                                disabled={
                                    !values.Event_CALENDAR_SCHEDULE_STARTTIME
                                }
                                name='Event_CALENDAR_SCHEDULE_ENDDATE'
                                onChange={(e) => {
                                    //form update scenario
                                    setFieldValue(
                                        'Event_CALENDAR_SCHEDULE_ENDDATE',
                                        e.target.value
                                    );
                                    setFieldValue(
                                        'Event_CALENDAR_SCHEDULE_ENDTIME',
                                        ''
                                    );
                                }}
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='time'
                                required
                                disabled={
                                    !values.Event_CALENDAR_SCHEDULE_ENDDATE
                                }
                                name='Event_CALENDAR_SCHEDULE_ENDTIME'
                            // onChange={(date) => {
                            //     if (date) {
                            //         setFieldValue(
                            //             'Event_CALENDAR_SCHEDULE_ENDTIME',
                            //             date._d
                            //         );
                            //     } else {
                            //         setFieldValue(
                            //             'Event_CALENDAR_SCHEDULE_ENDTIME',
                            //             moment()._d
                            //         );
                            //     }
                            // }}
                            />
                        </Col>
                        <Col sm='6'>
                            <FormikControl
                                control='check'
                                type='radio'
                                options={[
                                    { key: 'Active', value: '1' },
                                    { key: 'Inactive', value: '2' },
                                ]}
                                required
                                name='Event_CALENDAR_EVENT_STATUS'
                            />
                        </Col>
                        <Col sm='12'>
                            <FormikControl
                                control='textarea'
                                required
                                name='Event_CALENDAR_EVENT_DESC'
                            />
                        </Col>
                    </Row>
                    <FormSubmissionBtn onCancel={onCancel} />
                    {isSubmitting && <Loader />}
                </Form>
            )}
        </Formik>
    );
}

export default withAlert(EventForm);
