import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ReactComponent as GuideIcon } from '../../asset/icons/guide-icon.svg';
import { ReactComponent as ArrowRight } from '../../asset/icons/arrow-right-icon.svg';
import { ReactComponent as CloseButton } from '../../asset/icons/close-button-icon.svg';
import './modal.css';
import {
    img1,
    img2,
    img29,
    img31,
    img32,
    img33,
    img25,
    img26,
    img24,
    img15,
    img27,
    img10,
    img11,
    img12,
    img14,
    img13,
    img16,
    img17,
    img30,
    img18,
    img19,
    img20,
    img21,
    img22,
} from '../../asset/img/CityChaptersGuideImages';

const imageDescriptions = {
    1: {
        heading: 'Access Alumni Details in the Koble Web Application',
        desc: 'Access the Koble web application. Once logged in, navigate to the dashboard and select Alumni Details from the available options.',
    },
    2: {
        heading: 'Download Alumni Details from the Dashboard',
        desc: 'The dashboard will display the details of registered alumni. Click on the download icon to download the alumni details',
    },
    3: {
        heading: 'Display the downloaded Alumni Details from the Dashboard',
        desc: 'An Excel file of the Alumni details will be downloaded',
    },
    4: {
        heading: 'Access City Chapters in the Koble Web Application',
        desc: ' Access the Koble web application. Once logged in, navigate to the dashboard and select City Chapters from the available options.',
    },
    5: {
        heading: 'Tool-Tip for the Alumni City-Chapter Mapping.',
        desc: 'Click on the Tool-Tip icon to view the brief description for the Alumni City-Chapter Mapping',
    },
    6: {
        heading: 'Brief description from the Tool-Tip',
        desc: 'Displaying the brief description for the Alumni City-Chapter Mapping.',
    },
    7: {
        heading: 'Download City Chapters from the Dashboard',
        desc: 'Click on the download icon to download the City Chapters',
    },
    8: {
        heading: 'Download City Chapters from the Dashboard',
        desc: 'The excel file of city chapters list will be downloaded.',
    },
    9: {
        heading: 'Download City Chapters of a specific city',
        desc: 'Click on “City chapter Alumni list"',
    },
    10: {
        heading: 'Download City Chapters of a specific city',
        desc: 'Click on the download icon to download city chapters from a specific city',
    },
    11: {
        heading: 'Download City Chapters of a specific city',
        desc: 'An Excel file of the city chapter will be downloaded',
    },
    12: {
        heading: 'Download the Bulk Upload Template',
        desc: 'Return to the "City Chapter" section and click on the marked area to download the bulk upload template',
    },
    13: {
        heading: 'Download the template',
        desc: 'Click on Download Template for downloading the template.',
    },
    14: {
        heading: 'Template Appearance',
        desc: 'The downloaded template will look like the one shown in the image.',
    },
    15: {
        heading: 'Enter City Chapter and Alumni IDs',
        desc: 'The user must enter the City Chapter ID and the Alumni ID in the corresponding columns to add the alumni to the city chapter',
    },
    16: {
        heading: 'Enter IDs and Saving the File',
        desc: 'Enter the CITY_CHAPTER_ID of Kochi and the ALUMNI_ID of Laksh into the respective fields, and then save the file',
    },
    17: {
        heading: 'Upload The Template',
        desc: 'Cick on Browse your file and select the previously created Template.',
    },
    18: {
        heading:
            'Upload and Continue for the bulk upload of Alumni City-Chapter Mapping',
        desc: 'Cick on Upload and Continue.',
    },
    19: {
        heading: 'Cancel the Upload',
        desc: 'To cancel the upload, click on the "Cancel" button or select the "X" icon',
    },
    20: {
        heading: 'Positive Scenario (Upload Successfull)',
        desc: 'The result is successful, and the data is updated accordingly.',
    },
    21: {
        heading: 'Verify Alumni Addition to the City Chapter',
        desc: 'Return to the city chapter and check "City Chapter Alumni in COCHIN”.',
    },
    22: {
        heading: 'Verify Alumni Addition to the City Chapter',
        desc: 'The new city chapter has been added successfully!',
    },
    23: {
        heading: 'Negative Scenario',
        desc: 'If the ID entered by the user is not defined, an error will occur.',
    },
    24: {
        heading: 'Negative Scenario',
        desc: 'If the file is empty, it will not be accepted.',
    },
};

const OpenModalButton = () => {
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    fontSize: '14px',
                    fontWeight: 500,
                    background: '#EEF7FF',
                    padding: '6px',
                    borderRadius: '16px',
                    alignItems: 'center',
                    color: '#1079FE',
                }}
            >
                Find Your Way with Ease
                <div
                    style={{
                        background: '#FFFFFF',
                        padding: '2px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginLeft: '12px',
                        borderRadius: '16px',
                        marginRight: '12px',
                    }}
                >
                    <GuideIcon
                        style={{
                            marginRight: '4px',
                            marginLeft: '4px',
                        }}
                    />{' '}
                    MappingGuide{' '}
                    <ArrowRight
                        style={{
                            marginRight: '4px',
                            marginLeft: '10px',
                        }}
                    />
                </div>
            </div>
        </>
    );
};

const MoadalBody = () => {
    const imgArray = [
        img1,
        img2,
        img29,
        img31,
        img32,
        img33,
        img25,
        img26,
        img24,
        img15,
        img27,
        img10,
        img11,
        img12,
        img14,
        img13,
        img16,
        img17,
        img30,
        img18,
        img19,
        img20,
        img21,
        img22,
    ];
    return (
        <div>
            {imgArray.map((image, index) => (
                <div key={index} style={{ padding: '10px' }}>
                    <div style={{ display: 'flex', marginBottom: '20px' }}>
                        <div
                            style={{
                                background: '#373737',
                                padding: '2px',
                                borderRadius: '50%',
                                margin: '5px',
                                height: '20px',
                                width: '20px',
                                fontWeight: 700,
                                fontSize: '11px',
                                color: '#fff',
                                textAlign: 'center',
                            }}
                        >
                            {index + 1}
                        </div>

                        <div style={{ marginLeft: '10px' }}>
                            <div
                                style={{
                                    fontWeight: 600,
                                    fontSize: '17px',
                                    color: '#000000',
                                }}
                            >
                                {imageDescriptions[index + 1]?.heading}
                            </div>
                            <div
                                style={{
                                    fontWeight: 400,
                                    fontSize: '10px',
                                    color: '#000000',
                                }}
                            >
                                {imageDescriptions[index + 1]?.desc}
                            </div>
                        </div>
                    </div>

                    <img
                        src={image}
                        alt={`City Chapter ${index + 1}`}
                        loading="lazy"
                        style={{
                            borderRadius: '10px',
                            maxWidth: '90%',
                            display: 'block',
                            margin: '0 auto 20px 40px',
                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.5)',
                        }}
                    />
                    {index < imgArray.length - 1 && (
                        <div
                            style={{
                                height: '20px',
                                backgroundColor: '#BEBEBE',
                                width: 'calc(100% + 70px)',
                                margin: '0 -35px',
                            }}
                        />
                    )}
                </div>
            ))}
        </div>
    );
};

const MappingBulkUploadUserGuide = () => {
    return (
        <GuideModal renderModalBody={MoadalBody}>
            <OpenModalButton />
        </GuideModal>
    );
};

export default MappingBulkUploadUserGuide;

function GuideModal({
    renderModalBody = () => { },
    onHiding = () => { },
    modalAttrs,
    children,
    disabled,
}) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div
                type={`${disabled ? '' : 'button'}`}
                className="d-inline-block"
                style={{ opacity: disabled ? '.5' : '1' }}
                onClick={() => {
                    if (disabled) return;
                    setIsModalOpen(true);
                }}
            >
                {children}
            </div>

            <Modal
                centered
                show={isModalOpen}
                onHide={() => {
                    onHiding();
                    closeModal();
                }}
                backdrop="static"
                scrollable="false"
                dialogClassName="cc-modal-body"
                contentClassName="cc-modal-content"
                {...modalAttrs}
            >
                <div
                    className="text-center"
                    style={{
                        display: 'flex',
                        background: '#262626',
                        paddingLeft: '21px',
                        paddingTop: '30px',
                        paddingBottom: '30px',
                        alignItems: 'center',
                        paddingRight: '21px',
                    }}
                >
                    <span
                        className="m-0 h5"
                        style={{
                            textAlign: 'start',
                            width: '80%',
                            fontSize: '35px',
                            fontWeight: 700,
                            color: '#FFFFFF',
                        }}
                    >
                        Mapping Guide
                    </span>

                    <CloseButton
                        onClick={closeModal}
                        style={{
                            width: '5%',
                            marginLeft: 'auto',
                        }}
                        role="button"
                    />
                </div>

                <Modal.Body className="p-4 p-md-4">
                    {renderModalBody(closeModal)}
                </Modal.Body>
            </Modal>
        </>
    );
}
