import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);
function AlumniGraduated({ data }) {
    const { data: rawData, labels, batches } = data;

    if (!data?.labels?.length) {
        return (
            <>
                <div className='p-5 bg-white rounded-smoother mt-3 card-shadow'>
                    No data available
                </div>
            </>
        );
    }

    const colors = [
        '#98BAE7',
        '#FEC260',
        '#C0D8C0',
        '#EDD2F3',
        '#FF9D72',
        '#90D8FF',
        '#59886B',
        '#C8C9EC',
        '#D4ECDD',
        '#F0C38E',
    ];
    const generateRandomColor = () =>
        `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`;
    const batchColorMap = {};
    batches.forEach((batch, i) => {
        batchColorMap[batch] = generateRandomColor() || colors[i % colors.length];
    });
    const datasets = [];

    labels.forEach((label, index) => {
        const counts = new Array(6).fill(0);

        rawData[index].forEach((item, i) => {
            if (item.YearOfGraduation === label) {
                counts[index] = item.COUNT_ALUMNI;
            }

            datasets.push({
                label: item.BATCH_NAME,
                data: [...counts],
                backgroundColor: batchColorMap[item.BATCH_NAME],
                borderWidth: 0,
                maxBarThickness: 15,
            });
        });
    });

    const graphData = {
        labels: labels,
        datasets: datasets,
    };

    const options = {
        plugins: {
            legend: { display: false },
        },
        scales: {
            x: {
                grid: {
                    drawOnChartArea: false,
                    borderColor: '#bbb',
                    drawTicks: false,
                },
                ticks: {
                    padding: 15,
                    font: {
                        family: 'Inter',
                    },
                },
                stacked: true,
            },
            y: {
                grid: {
                    borderColor: '#bbb',
                    borderDash: [8],
                    color: '#dee2e6',
                    drawTicks: false,
                },
                beginAtZero: true,
                ticks: {
                    beginAtZero: true,
                    padding: 15,
                },
                stacked: true,
            },
        },
    };
    return (
        <>
            <div className='small h6 text-dark'>
                Alumni graduated
            </div>
            <div className='text-muted' style={{ fontSize: '.75em' }}>
                Year Based
            </div>
            <div className='p-3 bg-white rounded-smoother mt-3 card-shadow'>
                <Bar data={graphData} options={options} height={180} />
            </div>
        </>
    );
}

export default AlumniGraduated;
